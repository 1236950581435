import { post } from 'aws-amplify/api';
import general from '../utils/general';
import { fetchAuthSession, fetchUserAttributes, getCurrentUser } from 'aws-amplify/auth';
import { AuthInfo } from '../App';

export class TeamDao {

  static async request(body: any) {
    const headers = await general.getAuthorizationHeaders()
    const operation = post({
      apiName: 'apiMain',
      path: '/data',
      options: { headers, body: body || {} }
    });
    const res = await operation.response
    return res.body.json()
  }

  static async listPersonalInvitations() {
    return this.request({
      action: 'LIST_PERSONAL_INVITATIONS',
    })
  }

  static async updateInvitation(data: any) {
    return this.request({
      action: 'UPDATE_INVITATION',
      data: data
    })
  }

  static async dbSearch(data: any): Promise<any> {
    return this.request({
      action: 'DB_SEARCH',
      data: data
    })
  }

  static async dbGet(data: any): Promise<any> {
    return this.request({
      action: 'DB_GET',
      data: data
    })
  }

  static async getTeam() {
    return this.request({
      action: 'GET_TEAM',
    })
  }

  static async fetchAuthInfo(): Promise<AuthInfo> {
    const currentSession = await fetchAuthSession({ forceRefresh: true });
    const currentAuthenticatedUser = await getCurrentUser();
    const userAttributes = await fetchUserAttributes();
    const accessToken = await currentSession.tokens?.accessToken
    const team = await TeamDao.getTeam()
    const cognitoGroups = accessToken?.payload['cognito:groups'];
  
    const userId = currentSession.userSub as string
    const teamId = cognitoGroups?.toString() as string
  
    return {
      teamId,
      userId,
      currentSession,
      currentAuthenticatedUser,
      userAttributes,
      team: (team as any)?.data || null
    }
  }

}