import { Button, Card, Flex, Loader, StepperField } from '@aws-amplify/ui-react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { post } from 'aws-amplify/api';
import { useContext, useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoadingBars from '../custom-components/LoadingBars';
import StripeCheckoutForm from '../custom-components/StripeCheckoutForm';
import { GlobalContext, GlobalContextProps } from '../global-context';
import general from '../utils/general';
import ReactModal from 'react-modal';

const stripePromise = loadStripe('pk_live_51OOFh7KY6MbQuvkDU5JEPlzdajUqjUZjaWdK9eEAJ4Zf9XbKgUNtOyeywfIKltHUSaUqlrLXl8bkqz3zetOvJqVl00KZtJGPlP');
// const stripePromise = loadStripe('pk_test_51OOFh7KY6MbQuvkDG3ZPyPFHsdxDxqYf2T5fCxT2iTwqmdA5No6e1t2fiTE9qfYk99NgpgtDwW0mQMntwkPtZ2AD00OvVlVaEC');

export function Settings(props: any) {

  const globalContext = useContext(GlobalContext);
  general.setTitle(document, 'Settings', globalContext);

  const location = useLocation();
  const [isDownloadingReceipt, setIsDownloadingReceipt] = useState<number|undefined>(undefined);
  const stripeCheckoutFormRef = useRef<any>(null);

  const [isSubscribing, setIsSubscribing] = useState(false);
  const [offeredTiers, setOfferedTiers] = useState<{ monthly: string, annual: string, monthlyPrice: number, annualPrice: number, annualMonthlyPrice: number}>({
    monthly: 'MONTHLY_500',
    annual: 'ANNUAL_500',
    monthlyPrice: 500,
    annualPrice: 6000,
    annualMonthlyPrice: 400
  });
  const [team, setTeam] = useState<any>();
  const [exportHistory, setExportHistory] = useState<any[]>();
  const [serviceLimits, setServiceLimits] = useState<any[]>();
  const [teamMembers, setTeamMembers] = useState<any>();
  const [isLoadingTeamMembers, setIsLoadingTeamMembers] = useState(false);
  const [isShowingHistory, setIsShowingHistory] = useState(false);
  const [invoices, setInvoices] = useState<any>();
  const [isLoadingInvoices, setIsLoadingInvoices] = useState(false);
  const [updatedTier, setUpdatedTier] = useState<string>();
  const [updatedNrSeats, setUpdatedNrSeats] = useState(1);

  async function onSubscribe() {
    if (stripeCheckoutFormRef.current) {
      setIsSubscribing(true)
      const res = await stripeCheckoutFormRef.current.handleSubmit();
      await subscribe(res)
    } else {
      toast.warn('Payment provider not yet initialized, please try again')
    }
  }

  // async function connectHubspot() {
  //   try {
  //     setIsConnectingHubspot(true)
  //     const headers = await general.getAuthorizationHeaders();
  //     const body = { isLocalhost: general.isLocalhost() };
  //     const postOperation = post({
  //       apiName: 'apiAuth',
  //       path: '/auth/hubspot',
  //       options: {
  //         headers,
  //         body
  //       }
  //     });
  //     const response = await postOperation.response
  //     const bodyJson = await response.body.json()
  //     const redirectUrl = bodyJson
  //     // TODOR
  //     window.open('', '_blank');
  //   } catch (err) {
  //     toast.warn('Something went wrong trying to connect Hubspot, please try again')
  //   } finally {
  //     setIsConnectingHubspot(false)
  //   }
  // }

  useEffect(() => {
    if (globalContext?.authInfo?.teamId) {
      loadTeam()
    }
  }, [globalContext.authInfo?.teamId, location.search])

  useEffect(() => {
    if (team?.tier?.startsWith('MONTHLY') || team?.tier?.startsWith('ANNUAL')) {
      loadTeamMembers()
      loadInvoices()
    }
  }, [team?.tier])

  async function loadTeam() {
    const headers = await general.getAuthorizationHeaders()
    const operation = post({
      apiName: 'apiMain',
      path: '/data',
      options: { headers, body: { action: 'GET_SETTINGS_PAGE' } }
    });
    const response = await operation.response
    const data = await response.body.json() as any

    const team = data?.data?.team
    const limits = data?.data?.limits
    const history = data?.data?.history

    setTeam(team)
    setServiceLimits(limits)
    setExportHistory(history)

    if (['37', '49', '75', '100', '150', '200', '250', '300', '350', '500'].includes(team?.offeredTier)) {
      const monthlyPrice = parseInt(team?.offeredTier)
      setOfferedTiers({
        monthly: `MONTHLY_${team?.offeredTier}`,
        annual: `ANNUAL_${team?.offeredTier}`,
        monthlyPrice: monthlyPrice,
        annualPrice: Math.round(monthlyPrice * 0.8) * 12,
        annualMonthlyPrice: Math.round(monthlyPrice * 0.8),
      })
    }
    setUpdatedTier(team?.tier)
  }

  async function loadTeamMembers() {
    setIsLoadingTeamMembers(true)
    const headers = await general.getAuthorizationHeaders()
    const bodyTeam: any = {
      action: 'GET_TEAM_MEMBERS',
      data: {}
    }
    const getTeamOperation = post({
      apiName: 'apiMain',
      path: '/data',
      options: { headers, body: bodyTeam }
    });
    const getTeamResponse = await getTeamOperation.response
    const getTeamData = await getTeamResponse.body.json() as any

    setTeamMembers(getTeamData?.data?.members)
    setIsLoadingTeamMembers(false)
  }

  async function loadInvoices() {
    setIsLoadingInvoices(true)
    const headers = await general.getAuthorizationHeaders()
    const bodyTeam: any = {
      action: 'LIST_INVOICES',
      data: {}
    }
    const getTeamOperation = post({
      apiName: 'apiMain',
      path: '/data',
      options: { headers, body: bodyTeam }
    });
    const getTeamResponse = await getTeamOperation.response
    const getTeamData = await getTeamResponse.body.json() as any

    setInvoices(getTeamData?.data?.invoices)
    setIsLoadingInvoices(false)
  }

  async function subscribe({ tokenId, paymentMethodId }: { tokenId: string, paymentMethodId: string }) {
    try {
      const headers = await general.getAuthorizationHeaders()
      const body: any = {
        action: 'CREATE_SUBSCRIPTION',
        data: {
          stripeCardToken: tokenId,
          stripePaymentMethod: paymentMethodId,
          tier: updatedTier,
          nrSeats: updatedNrSeats
        }
      }
      post({
        apiName: 'apiMain',
        path: '/data',
        options: { headers, body }
      });
      await loadTeam()
      toast.success('Subscription was successfully created')
    } catch (e) {
      console.error(e)
      toast.warn('Something went wrong creating a subscription, please try again')
    } finally {
      setIsSubscribing(false)
    }
  }

  async function downloadReceipt(id: string, index: number) {
    setIsDownloadingReceipt(index)
    try {
      const headers = await general.getAuthorizationHeaders();
      const body: any = {
        action: 'DOWNLOAD_INVOICE',
        data: { id }
      }

      const postOperation = post({
        apiName: 'apiMain',
        path: '/data',
        options: {
          headers,
          body
        }
      });
      const response = await postOperation.response
      const bodyJson = await response.body.json() as any

      await general.downloadUrl(bodyJson?.data?.downloadUrl, bodyJson?.data?.fileName)
    } catch (error: any) {
      toast.warn(general.getErrorMessage('Something went wrong downloading a receipt', error))
      console.error(error)
    } finally {
      setIsDownloadingReceipt(undefined)
    }
  }

  function getServiceLimitDescription(limitName: string) {
    return {
      'CSV_EXPORT_NR_CONTACTS__ONEOFF': 'Number of <strong>contacts</strong> that can be exported to a CSV <strong>at a time</strong> <span class="desc-3">(in a single click on the \'Export\' button)</span>',
      'CSV_EXPORT_NR_CONTACTS__DAILY': 'Number of <strong>contacts</strong> that can be exported to a CSV <strong>per day</strong>',
      'CSV_EXPORT_NR_CONTACTS__TOTAL': 'Number of <strong>contacts</strong> that can be exported to a CSV <strong>in total</strong>',
      'CSV_EXPORT_NR_SCHOOLS__ONEOFF': 'Number of <strong>schools</strong> that can be exported to a CSV <strong>at a time</strong> <span class="desc-3">(in a single click on the \'Export\' button)</span>',
      'CSV_EXPORT_NR_DISTRICTS__ONEOFF': 'Number of <strong>districts</strong> that can be exported to a CSV <strong>at a time</strong> <span class="desc-3">(in a single click on the \'Export\' button)</span>',
      'NR_CONTACT_SEARCHES__DAILY': 'Number of <strong>contact searches per day</strong> <span class="desc-3">(this essentially means number of clicks on the \'Search\' button)</span>',
    }?.[limitName] || 'N/A'
  }

  return <GlobalContext.Consumer>
    {
      (globalContextProps: GlobalContextProps) => <>
        <div className="content content-1000">
          <h2>Settings</h2>
          <Card variation='outlined' style={{fontSize: 14, marginTop: 20}}>
            <h3 style={{ marginTop: 0 }}>Subscription</h3>
            {
              team ? <>
                {
                  !team?.tier?.startsWith('ANNUAL_ONEOFF_')
                    ? <Flex className='tiers' columnGap={15}>
                        <>
                          <div
                            className={`tier ${updatedTier === offeredTiers.monthly ? 'active' : ''} ${team?.tier === 'TRIAL' || team?.tier === offeredTiers.monthly ? 'enabled' : 'disabled'}`}
                            onClick={() => {
                              if (team?.tier === 'TRIAL') {
                                setUpdatedTier(offeredTiers.monthly)
                              }
                            }}
                          >
                            <h4>Monthly Plan</h4>
                            <div className="description">${offeredTiers.monthlyPrice}/user/month, billed monthly</div>
                            { team?.tier === offeredTiers.monthly && <div className="current">Current plan</div> }
                          </div>
                          <div
                            className={`tier ${updatedTier === offeredTiers.annual ? 'active' : ''} ${team?.tier === 'TRIAL' || team?.tier === offeredTiers.annual ? 'enabled' : 'disabled'}`}
                            onClick={() => {
                              if (team?.tier === 'TRIAL') {
                                setUpdatedTier(offeredTiers.annual)
                              }
                            }}
                          >
                            <h4>Annual Plan <span className='discount'>20% off</span></h4>
                            <div className="description">${offeredTiers.annualMonthlyPrice}/user/month, billed annually</div>
                            { team?.tier === offeredTiers.annual && <div className="current">Current plan</div> }
                          </div>
                        </>
                      </Flex>
                    : <></>
                }
                {
                  team?.tier === 'TRIAL' && updatedTier !== 'TRIAL' ? <div style={{ marginTop: 20 }}>
                    <Elements stripe={stripePromise}>
                      <Flex style={{ marginTop: 30 }}>
                        <div style={{ flex: 1 }}>
                          <div style={{ marginBottom: 5 }}>Number of Users</div>
                          <StepperField
                            min={1}
                            max={1000}
                            step={1}
                            label="Number of Seats"
                            labelHidden={true}
                            value={updatedNrSeats}
                            onStepChange={(nrSeats) => {
                              setUpdatedNrSeats(nrSeats)
                            }}
                          />
                          <div style={{ marginBottom: 5, marginTop: 20 }}>Card Details</div>
                          <StripeCheckoutForm ref={stripeCheckoutFormRef} />
                        </div>
                        <div style={{ flex: 1 }}>
                          <Flex direction='column' alignItems='flex-end' gap={8}>
                            <div style={{ textAlign: 'right', width: 220, marginTop: 5, fontWeight: 'bold' }}>Payment</div>
                            <div style={{ textAlign: 'right', width: 220 }}>{ updatedNrSeats } user{ updatedNrSeats === 1 ? '' : 's' }</div>
                            <div style={{ textAlign: 'right', width: 220 }}>{ updatedTier?.startsWith('MONTHLY') ? `$${offeredTiers.monthlyPrice}/user/month` : `$${offeredTiers.annualMonthlyPrice}/user/month`}</div>
                            <div style={{ textAlign: 'right', width: 220, fontWeight: 'bold', borderTop: '1px dashed var(--amplify-colors-neutral-100)', paddingTop: 8 }}>${ updatedNrSeats * (updatedTier?.startsWith('MONTHLY') ? offeredTiers.monthlyPrice : offeredTiers.annualPrice)} due today</div>
                            <div style={{ textAlign: 'right', width: 220, fontStyle: 'italic', color: 'var(--amplify-colors-neutral-80)' }}>(Next charge in { updatedTier?.startsWith('MONTHLY') ? '1 month' : updatedTier?.startsWith('ANNUAL') ? '12 months' : updatedTier === 'MICRO' ? '1 month' : ''})</div>
                          </Flex>
                        </div>
                      </Flex>
                      <Flex justifyContent='end' style={{ marginTop: 50 }}>
                        { isSubscribing && <Loader style={{ marginTop: 9 }} /> }
                        <Button
                          disabled={isSubscribing}
                          onClick={onSubscribe}
                          size='small'
                          variation='primary'
                        >
                          Subscribe
                        </Button>
                      </Flex>
                    </Elements>
                  </div> : <></>
                }
                {
                  team?.tier !== 'TRIAL' && <div style={{ marginTop: 20 }}>
                    {
                      team?.tier?.startsWith('ANNUAL_ONEOFF_')
                        ? <div style={{ marginBottom: 5 }}>
                            <span className='amplify-label' style={{ display: 'inline-block', width: 180 }}>Tier</span> ${ team?.tier?.split('ANNUAL_ONEOFF_')[1] }/year
                          </div>
                        : <></>
                    }
                    <div style={{ marginBottom: 5 }}>
                      <span className='amplify-label' style={{ display: 'inline-block', width: 180 }}>Subscribed By</span> { team.createdByUserEmail }
                    </div>
                    <div style={{ marginBottom: 5 }}>
                      <span className='amplify-label' style={{ display: 'inline-block', width: 180 }}>Number of Users</span> { team.nrSeats }
                    </div>
                    {
                      isLoadingTeamMembers ?
                        <LoadingBars numberOfRows={1} /> :
                        <>
                          <div>
                            <span className='amplify-label' style={{ display: 'inline-block', width: 180 }}>Number of Seated Users</span> { teamMembers?.length } {  general.isOwner(globalContext) && teamMembers?.length < team.nrSeats && <span style={{ fontStyle: 'italic', marginLeft: 5, color: 'var(--amplify-colors-neutral-80)' }}>(Go to <Link to={'/team'}>Team page</Link> to invite users)</span>}
                          </div>
                        </>
                    }
                  </div>
                }
              </> : <LoadingBars numberOfRows={5} />
            }
            {
              invoices?.length && <div style={{ marginTop: 30 }}>
                <h3 style={{ marginTop: 0, marginBottom: 10 }}>Invoices</h3>
                <table style={{ width: '100%' }}>
                  <thead>
                    <tr>
                      <td className='desc-8'>Date</td>
                      <td className='desc-8'>Description</td>
                      <td className='desc-8'>Billed To</td>
                      <td className='desc-8'>Qty</td>
                      <td className='desc-8'>Unit Price</td>
                      <td className='desc-8'>Amount</td>
                      <td className='desc-8'>Status</td>
                      <td></td>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      invoices.map((invoice: any, index: number) => {
                        return <tr key={index} style={{ borderTop: '1px solid var(--amplify-colors-neutral-40)' }}>
                          <td>{ general.epochToHumanReadableDate2(invoice.effective_at * 1000) }</td>
                          <td>{ globalContext.environment.name } { invoice.interval === 'month' ? '(monthly)' : invoice.interval === 'year' ? '(annual)' : '' }</td>
                          <td>{ invoice.customer_email }</td>
                          <td>{ invoice.quantity }</td>
                          <td>${ Math.round(invoice.unit_amount / 100) }.00</td>
                          <td>${ Math.round(invoice.amount_due / 100) }.00</td>
                          <td style={{ color: invoice.status === 'paid' ? 'var(--amplify-colors-green-100)' : 'inherit' }}>{ general.capitalizeFirstLetter(invoice.status) }</td>
                          <td style={{ textAlign: 'right', width: '130px' }}>
                            <div style={{ display: 'flex', justifyContent: 'end' }}>
                              <div style={{ width: '30px' }}>
                                {
                                  isDownloadingReceipt === index &&
                                    <Loader style={{ marginRight: 10, transform: 'translateY(6px)' }} />
                                }
                              </div>
                              <Button
                                className='tiny'
                                isDisabled={isDownloadingReceipt === index}
                                onClick={() => downloadReceipt(invoice.id, index)}
                              >Get Receipt</Button>
                            </div>
                          </td>
                        </tr>
                      })
                    }
                  </tbody>
                </table>
              </div>
            }
            <h3 style={{ marginTop: 30, marginBottom: 10 }}>Service Limits</h3>
            {
              serviceLimits ? <>
                <table style={{ width: '100%' }}>
                  <thead>
                    <tr>
                      <td className='desc-8'>Service Limit Description</td>
                      <td className='desc-8'>Value</td>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      serviceLimits.map((limit: any, index: number) => {
                        return <tr key={index}>
                          <td>
                            <div dangerouslySetInnerHTML={{ __html: getServiceLimitDescription(limit.name) }}></div>
                          </td>
                          <td>
                            { general.addCommasToNumber(limit.limitApplied) }
                            {
                              limit.name === 'CSV_EXPORT_NR_CONTACTS__DAILY' &&
                                <span className='desc-3' style={{ marginLeft: 8, cursor: 'pointer', color: 'var(--amplify-colors-purple-60)' }} onClick={() => setIsShowingHistory(true)}>History</span>
                            }
                          </td>
                        </tr>
                      })
                    }
                  </tbody>
                </table>
                <div className='desc-3' style={{ marginTop: 15, marginLeft: 3 }}>
                  Please contact us if you need to increase any of the service limits above
                </div>
              </> : <LoadingBars numberOfRows={5} />
            }
            {
              team?.eduleadApiKey ?
                <>
                  <h3 style={{ marginTop: 30, marginBottom: 10 }}>API</h3>
                  <div>
                    API Key: { team?.eduleadApiKey }
                  </div>
                </>
                : <></>
            }
          </Card>
          {/* <Card variation='outlined' style={{fontSize: 14, marginTop: 20}}>
            <h3 style={{ marginTop: 0 }}>Hubspot Integration</h3>
            {
              team ? <>
                {
                  team?.isHubspotConnected === true ?
                    <div>
                      <HiBadgeCheck size={20} color='var(--amplify-colors-green-80)' style={{ transform: 'translate(0, 4px)', marginRight: 5 }} />
                      Your account is connected to Hubspot <i>(connected by { team?.hubspotConnectedByName } on { general.epochToHumanReadableDate(team?.hubspotConnectedAt) })</i>
                    </div> :
                    <Button
                      isDisabled={isConnectingHubspot}
                      onClick={connectHubspot}
                      size='small'
                    >
                      Connect Hubspot Account
                    </Button>
                }
              </> : <LoadingBars numberOfRows={5} />
            }
          </Card> */}
        </div>
        <ReactModal
          isOpen={isShowingHistory}
          shouldCloseOnEsc={true}
          shouldCloseOnOverlayClick={true}
          onRequestClose={() => setIsShowingHistory(false)}
          style={general.getSliderDefaults()}
          closeTimeoutMS={200}
          parentSelector={general.getModalRoot}
        >
          <div style={{ padding: '0 15px' }}>
            <h4>CSV Export History</h4>
          </div>
          <Flex direction="column" style={{ gap: 0, margin: 20 }}>
            {
              exportHistory && <>
                <Flex style={{ marginBottom: 8 }}>
                  <div className='desc-8' style={{ width: 170 }}>Date</div>
                  <div className='desc-8'># contacts exported</div>
                </Flex>
                {
                  exportHistory?.map(item => {
                    return <Flex style={{ marginBottom: 10 }}>
                      <div style={{ fontSize: 14, width: 170, fontStyle: 'italic', color: 'var(--amplify-colors-neutral-90)' }}>
                        { new Date(item?.iso).toLocaleDateString() }
                      </div>
                      <div style={{ fontSize: 14, fontWeight: 'bold', color: 'var(--amplify-colors-neutral-100)' }}>
                        { item?.count }
                      </div>
                    </Flex>
                  })
                }
              </>
            }
          </Flex>
        </ReactModal>
      </>
    }
  </GlobalContext.Consumer>
}
