import { fetchAuthSession } from 'aws-amplify/auth';
import { GlobalContextProps } from "../global-context";
import { post } from '@aws-amplify/api-rest';
import counties from '../counties.json';

const { Buffer } = require('buffer');

const general = {
  sortByCreatedAtISO(array: any[]) {
    return array.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
  },
  daysAgo(dateString: string, format = null) {
    try {
      if (typeof dateString !== 'string') return null

      // Parse the input date string as an ISO date
      const inputDate = new Date(dateString) as any;

      // Get the current date
      const currentDate = new Date() as any;

      // Calculate the difference in time (milliseconds) between the two dates
      const timeDifference = currentDate - inputDate;

      // Convert the time difference from milliseconds to days
      const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));
      const minutesDifference = Math.floor(timeDifference / (1000 * 60));

      if (minutesDifference <= 5) return 'just now';
      if (minutesDifference <= 60) return `${minutesDifference} minute${ hoursDifference === 1 ? '' : 's' } ago`;
      if (hoursDifference < 12) return `${hoursDifference} hour${ hoursDifference === 1 ? '' : 's' } ago`;
      if (daysDifference === 0) return 'today';
      return `${daysDifference} day${ daysDifference === 1 ? '' : 's' } ago`;
    } catch (err) {
      return null
    }
  },
  roundString(str: string) {
    try {
      return Math.round(parseFloat(str)).toString();
    } catch {
      return str;
    }
  },
  replaceMiddleWithDots(s: string, startLen: number, endLen: number, delimiter = ' ... '): string {
    if (startLen + endLen >= s.length) {
      return s;
    }
    return s.slice(0, startLen) + delimiter + s.slice(-endLen);
  },
  getHumanReadableReligiousAffiliation(religiousAffiliation: string) {
    return {
      'AFRICAN_METHODIST_EPISCOPAL': 'African Methodist Episcopal',
      'AMISH': 'Amish',
      'ANGLICAN': 'Anglican',
      'ASSEMBLY_OF_GOD': 'Assembly of God',
      'BAPTIST': 'Baptist',
      'BRETHREN': 'Brethren',
      'CALVINIST': 'Calvinist',
      'CATHOLIC': 'Catholic',
      'CHRISTIAN': 'Christian',
      'CHURCH_OF_CHRIST': 'Church of Christ',
      'CHURCH_OF_GOD': 'Church of God',
      'CHURCH_OF_GOD_IN_CHRIST': 'Church of God in Christ',
      'CHURCH_OF_THE_NAZARENE': 'Church of the Nazarene',
      'DISCIPLES_OF_CHRIST': 'Disciples of Christ',
      'EPISCOPAL': 'Episcopal',
      'EVANGELICAL_LUTHERAN_CHURCH_IN_AMERICA': 'Evangelical Lutheran Church in America',
      'FRIENDS': 'Friends',
      'GREEK_ORTHODOX': 'Greek Orthodox',
      'ISLAMIC': 'Islamic',
      'JEWISH': 'Jewish',
      'LATTER_DAY_SAINTS': 'Latter Day Saints',
      'LUTHERAN_CHURCH_MISSOURI_SYNOD': 'Lutheran Church Missouri Synod',
      'MENNONITE': 'Mennonite',
      'METHODIST': 'Methodist',
      'NONSECTARIAN': 'Nonsectarian',
      'OTHER': 'Other',
      'OTHER_LUTHERAN': 'Other Lutheran',
      'PENTECOSTAL': 'Pentecostal',
      'PRESBYTERIAN': 'Presbyterian',
      'SEVENTH_DAY_ADVENTIST': 'Seventh Day Adventist',
      'WISCONSIN_EVANGELICAL_LUTHERAN_SYNOD': 'Wisconsin Evangelical Lutheran Synod'
    }[religiousAffiliation];
  },
  getHumanReadableType2(type2: string) {
    return {
      'ALTERNATIVE': 'Alternative',
      'EARLY_CHILDHOOD_DAY_CARE': 'Early Childhood Day Care',
      'MILITARY': 'Military',
      'MONTESSORI': 'Montessori',
      'SPECIAL_PROGRAM_EMPHASIS': 'Special Program Emphasis',
      'ONLINE': 'Online',
      'SPECIAL_EDUCATION': 'Special Education',
      'VOCATIONAL_TECHNICAL': 'Vocational Technical',
      'WALDORF': 'Waldorf',
      'ACUTE_CARE_DOD': 'Acuta Care, DoD',
      'ACUTE_CARE_HOSPITAL': 'Acute Care',
      'ACUTE_CARE_VETERANS': 'Acute Care, Veterans',
      'CHARTER': 'Charter',
      'CHILDRENS': `Children's hospital`,
      'CRITICAL_ACCESS': 'Critical Access',
      'PSYCHIATRIC': 'Psychiatric',
    }[type2];
  },
  isAdmin(teamId: string | undefined) {
    if (!teamId) return false
    return [
      '62948182-2201-4c73-952c-35d56474c039',
      '326f0a9f-20d4-4f90-b2d9-ff61053fbb20'
    ].includes(teamId)
  },
  getQ({ rules, combinator = 'and' }: { rules: any[], combinator?: 'and'|'or' }) {
    const q = {
      rules: rules.map(rule => {
        return {
          field: rule.field,
          operator: rule.operator,
          valueSource: 'value',
          value: rule.value
        }
      }),
      combinator: combinator
    }
    return btoa(JSON.stringify(q))
  },
  formatDate(dateString?: string | null) {
    try {
      if (!dateString) throw new Error('not a string')
      const dt = new Date(dateString)
      return `${dt.getMonth() + 1}/${dt.getDate()}/${dt.getFullYear()}`
    } catch {
      return '-'
    }
  },
  formatDateTime(dateString?: string | null) {
    try {
      if (!dateString) throw new Error('not a string')
      const dt = new Date(dateString);
      const hours = dt.getHours().toString().padStart(2, '0');
      const minutes = dt.getMinutes().toString().padStart(2, '0');
      const seconds = dt.getSeconds().toString().padStart(2, '0');
      return `${dt.getMonth()}/${dt.getDate()}/${dt.getFullYear()} ${hours}:${minutes}:${seconds}`;
    } catch {
      return '-'
    }
  },
  formatYYYYMMDD(dateString: string) {
    // Split the input date string into its components
    const [year, month, day] = dateString.split('-');
    
    // Remove leading zeros from month and day
    const formattedMonth = parseInt(month, 10);
    const formattedDay = parseInt(day, 10);
    
    // Construct the new date format
    return `${formattedMonth}/${formattedDay}/${year}`;
  },
  async saveTag({ resource, resourceId, tag }: any) {
    const headers = await general.getAuthorizationHeaders()
    const postOperation = post({
      apiName: 'apiMain',
      path: '/data',
      options: {
        headers,
        body: {
          action: 'TAG',
          data: {
            resource,
            resourceId,
            tag
          }
        }
      }
    });
    await postOperation.response
  },
  async deleteTag({ resource, resourceId, tag }: any) {
    const headers = await general.getAuthorizationHeaders()
    const postOperation = post({
      apiName: 'apiMain',
      path: '/data',
      options: {
        headers,
        body: {
          action: 'UNTAG',
          data: {
            resource,
            resourceId,
            tag
          }
        }
      }
    });
    await postOperation.response
  },
  isOwner(globalContext: GlobalContextProps) {
    return globalContext.authInfo?.userAttributes?.profile === 'OWNER'
  },
  getErrorMessage(str: string, error: any) {
    return `${str}, please try again.${error?.message ? ' ' + this.ellipsisInMiddle(error.message, 300) : ''}`
  },
  ellipsisInMiddle(str: string, ln = 56) {
    if (str.length <= ln) return str
    const start = str.substring(0, ln - 20)
    const end = str.substring(str.length - 17)
    return `${start}...${end}`;
  },
  getCSSFromId(id: string) {
    if (typeof id !== 'string') return 0
    return id.charCodeAt(0) % 10
  },
  getInitials(name: string) {
    if (typeof name !== 'string') return '?'
    const segments = name.split(' ')
    let initials = '';
    if (segments[0]) initials += segments[0].substring(0, 1)
    if (segments[1]) initials += segments[1].substring(0, 1)
    return initials.length ? initials.toUpperCase() : '?'
  },
  async downloadUrl(s3Link: string, fileName: string) {
    if (typeof s3Link !== 'string') throw new Error('Invalid download link')
    if (typeof fileName !== 'string') throw new Error('Invalid file name')
    if (!s3Link.startsWith('https')) throw new Error('Invalid download link')

    const response = await fetch(s3Link);

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const blob = await response.blob();

    const url = window.URL.createObjectURL(new Blob([blob]));
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  },
  deduplicateArrayByKey(arr: any[], key: string): object[] {
    const seen = new Set();
    return arr.filter((item) => {
      const keyValue = item[key];
      if (!seen.has(keyValue)) {
        seen.add(keyValue);
        return true;
      }
      return false;
    });
  },
  getCreatableStyles() {
    return {
      multiValueLabel: (provided: any, state: any) => ({ ...provided, fontSize: '100%', paddingTop: '3px', paddingRight: '10px', paddingBottom: '3px', paddingLeft: '10px' }),
      multiValue: (provided: any, state: any) => ({ ...provided, borderRadius: '15px', backgroundColor: 'var(--amplify-colors-purple-10)' }),
      control: (provided: any, state: any) => ({ ...provided, borderColor: 'var(--amplify-colors-neutral-20)' }),
      valueContainer: (provided: any, state: any) => ({ ...provided, padding: '2px' }),
      placeholder: (provided: any, state: any) => ({ ...provided, marginLeft: '10px' }),
      input: (provided: any, state: any) => ({ ...provided, marginLeft: '10px' }),
    }
  },
  getCreatableStylesCompact() {
    return {
      multiValueLabel: (provided: any, state: any) => ({ ...provided, fontSize: '100%', paddingTop: '1px', paddingRight: '8px', paddingBottom: '2px', paddingLeft: '8px' }),
      multiValue: (provided: any, state: any) => ({ ...provided, borderRadius: '15px', backgroundColor: 'var(--amplify-colors-neutral-10)', margin: '0 0 0 2px' }),
      control: (provided: any, state: any) => ({ ...provided, borderColor: 'var(--amplify-colors-neutral-40)', padding: '2px 0', minHeight: '30px', transition: 'none' }),
      valueContainer: (provided: any, state: any) => ({ ...provided, padding: '0px !important' }),
      placeholder: (provided: any, state: any) => ({ ...provided, marginLeft: '10px' }),
      input: (provided: any, state: any) => ({ ...provided, marginLeft: '10px', padding: '0px' }),
      indicatorsContainer: (provided: any, state: any) => ({ ...provided, padding: '0px 5px !important' }),
      dropdownIndicator: (provided: any, state: any) => ({ ...provided, padding: '0px 5px !important' }),
      multiValueRemove: (provided: any, state: any) => ({ ...provided, padding: '0px 1px !important' }),
    }
  },
  getSortArrowStyle() {
    return { fontSize: 18, transform: 'translateY(3px)' };
  },
  capitalizeFirstLetter(str: string) {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
  },
  getTabIndex(tab: string, tabs: any = { states: 'States', zip: 'ZIP Codes', map: 'Map' }) {
    return tabs[tab]
  },
  getTabName(tab: string, tabs: any = { 'States': 'states', 'ZIP Codes': 'zip', 'Map': 'map' }) {
    return tabs[tab]
  },
  epochToHumanReadableDate2(epoch: number | string | null | undefined) {
    if (!epoch) return ''
    return new Date(epoch).toLocaleDateString('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' });
  },
  epochToHumanReadableDate(epoch: number | string | null | undefined) {
    if (!epoch) return ''

    const epochNormalized = typeof epoch === 'string' ? parseInt(epoch) : epoch

    const date = new Date(epochNormalized);
  
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
  
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  },
  async getAuthorizationHeaders() {
    const authSession = await fetchAuthSession()
    const token = authSession.tokens?.idToken
    if (!token) throw new Error('Failed to retrieve ID token')
    return { Authorization: token.toString() };
  },
  addCommasToNumber(number: number) {
    // Convert the number to a string
    const numString = number.toString();

    // Use a regular expression to add commas
    const numberWithCommas = numString.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return numberWithCommas;
  },
  isLocalhost() {
    try {
      return window.location.hostname === 'localhost'
    } catch {
      return false
    }
  },
  getQueryParamValuesOrNull(queryParams: URLSearchParams, paramName: string) {
    try {
      return queryParams.get(paramName) ? queryParams.get(paramName)?.split(',') : []
    } catch {
      return []
    }
  },
  getQueryParamValueOrNull(queryParams: URLSearchParams, paramName: string) {
    try {
      const val = queryParams.get(paramName) || null
      if (!val) return val
      if (val === 'true') return true
      if (val === 'false') return false
      if (!isNaN(parseInt(val))) return parseInt(val)
      return val
    } catch {
      return ''
    }
  },
  getStatesFromBelongsTo(belongsTo: any[]) {
    if (!Array.isArray(belongsTo)) return ''
    const states = new Set()
    for (const item of belongsTo) {
      states.add(this.getStateName(item['state']))
    }
    return general.trim(Array.from(states).join(', '), 20)
  },
  trim(str: string, length: number) {
    if (typeof str !== 'string') return str
    if (str.length > length) {
      return `${str.substring(0, length).trim()}...`
    }
    return str;
  },
  getModalRoot(): HTMLElement {
    return document.getElementById('modalroot') as HTMLElement
  },
  getSliderDefaults(contentOverride = {}) {
    return {
      content: Object.assign({
        top: 0,
        left: 'auto',
        right: 0,
        bottom: 0,
        width: '400px',
        border: 'none',
        overflow: 'auto',
        borderRadius: 0,
        outline: 'none',
        padding: 0,
      }, contentOverride),
      overlay: {
        background: 'rgba(72, 72, 101, 0.1)'
      }
    }
  },
  getModalDefaults() {
    return {
      content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        width: '600px',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        border: 'none'
      },
      overlay: {
        background: 'rgba(72, 72, 101, 0.1)'
      }
    }
  },
  getTrueKeys(obj: object): Array<[string, any]> {
    return Object
      .entries(obj)
      .filter(item => !!item[1])
  },
  isItMe(globalContextProps: GlobalContextProps, userId: string) {
    return globalContextProps.withAuthenticatorProps.user?.username === userId;
  },
  getWholeName(payload: any) {
    if (!payload) return '';
    return [payload.given_name, payload.family_name].join(' ');
  },
  getRandomString(length: number) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      result += characters.charAt(randomIndex);
    }
    return result;
  },
  isValidZipCode(zipCode: string) {
    const zipCodeRegex = /^\d{5}$/;
    return zipCodeRegex.test(zipCode);
  },
  validateEmail(email: string) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  },
  isManager: (title?: string) => {
    try {
      if (!title) return false;
      const normalizedTitle = title.toLowerCase();
      const keywords = [
        'manager',
        'superintendent',
        'principal',
        'director'
      ];
      return keywords.some(keyword => normalizedTitle.includes(keyword));
    } catch(e) {
      return false;
    }
  },
  uniqueWebsite: (url?: string) => {
    if (!url) return null;
    return general.normalizeKeyKeepNumbers(general.removeHttpWww(url))
  },
  removeHttpWww: (url: string) => {
    return url
      .replace(/^http:\/\//g, '')
      .replace(/^https:\/\//g, '')
      .replace(/^www\./g, '')
      .trim()
  },
  normalizeKeyKeepNumbers: (key: string) => {
    return key
      .replace(/[^a-zA-Z0-9]/g, '-')
      .replace(/-+/g, '-')
      .replace(/-$/g, '')
      .replace(/^-/g, '')
      .trim()
      .toLowerCase()
  },
  setTitle: (document: Document, title: string, globalContext: GlobalContextProps) => {
    document.title = `${title} / ${globalContext.environment.name}`;
  },
  findMaxMins: (items: any[]) => {
    const res: any = {};
    try {
      const keys = Object
        .keys(items[0])
        .filter(key => !isNaN(items[0][key]))
      for (const key of keys) {
        const values = items.map(item => item[key]);
        res[key] = Math.max(...values);
      }
      return res;
    } catch (e) {
      return res;
    }
  },
  getGradient: (key: string, row: any, maxMins: any) => {
    try {
      const percentage = (row[key] / maxMins[key]) * 100;
      return `linear-gradient(to right, var(--amplify-colors-green-10) ${percentage}%, transparent ${percentage}%)`
    } catch (e) {
      return 'transparent'
    }
  },
  encode: (object: object | undefined) => {
    try {
      return Buffer.from(JSON.stringify(object)).toString('base64');
    } catch (e) {
      return null
    }
  },
  decode: (object: string | undefined) => {
    try {
      return JSON.parse(Buffer.from(object, 'base64').toString());
    } catch (e) {
      return null
    }
  },
  sortArrayByEnumValues: (array: any[], property: string, order: any[]) => {
    array.sort((a, b) => {
      const indexA = order.indexOf(a[property]);
      const indexB = order.indexOf(b[property]);

      if (indexA === -1) return 1;
      if (indexB === -1) return -1;

      return indexA - indexB;
    });
  },
  getDistrictQueryParamToState: (stateId: string | null) => {
    return general.encode({
      "filter": {
        "pk": {
          "eq": stateId
        },
        "dsName": {
          "contains":""
        },
        "fsclRevTotal": {
          "range": [0,280000000]
        },
        "totalSchools": {
          "range": [0,100]
        },
        "stfTotal": {
          "range": [0,57000]
        },
        "studentsTotal": {
          "range": [0,440000]
        }
      }
    })
  },
  getStateName: (stateId: string | null) => {
    if (!stateId) return null;
    return {
      AL: 'Alabama',
      AK: 'Alaska',
      AS: 'American Samoa',
      AZ: 'Arizona',
      AR: 'Arkansas',
      CA: 'California',
      CO: 'Colorado',
      CT: 'Connecticut',
      DE: 'Delaware',
      DC: 'District Of Columbia',
      FM: 'Federated States Of Micronesia',
      FL: 'Florida',
      GA: 'Georgia',
      GU: 'Guam',
      HI: 'Hawaii',
      ID: 'Idaho',
      IL: 'Illinois',
      IN: 'Indiana',
      IA: 'Iowa',
      KS: 'Kansas',
      KY: 'Kentucky',
      LA: 'Louisiana',
      ME: 'Maine',
      MH: 'Marshall Islands',
      MD: 'Maryland',
      MA: 'Massachusetts',
      MI: 'Michigan',
      MN: 'Minnesota',
      MS: 'Mississippi',
      MO: 'Missouri',
      MT: 'Montana',
      NE: 'Nebraska',
      NV: 'Nevada',
      NH: 'New Hampshire',
      NJ: 'New Jersey',
      NM: 'New Mexico',
      NY: 'New York',
      NC: 'North Carolina',
      ND: 'North Dakota',
      MP: 'Northern Mariana Islands',
      OH: 'Ohio',
      OK: 'Oklahoma',
      OR: 'Oregon',
      PW: 'Palau',
      PA: 'Pennsylvania',
      PR: 'Puerto Rico',
      RI: 'Rhode Island',
      SC: 'South Carolina',
      SD: 'South Dakota',
      TN: 'Tennessee',
      TX: 'Texas',
      UT: 'Utah',
      VT: 'Vermont',
      VI: 'Virgin Islands',
      VA: 'Virginia',
      WA: 'Washington',
      WV: 'West Virginia',
      WI: 'Wisconsin',
      WY: 'Wyoming'
    }[stateId];
  },
  getGradesObject: (it: string) => {
    return {
      // "g_nursery": "Nursery",
      "g_pk": "Pre-K",
      "g_kg": "Kindergarten",
      "g_1": "1st Grade",
      "g_2": "2nd Grade",
      "g_3": "3rd Grade",
      "g_4": "4th Grade",
      "g_5": "5th Grade",
      "g_6": "6th Grade",
      "g_7": "7th Grade",
      "g_8": "8th Grade",
      "g_9": "9th Grade",
      "g_10": "10th Grade",
      "g_11": "11th Grade",
      "g_12": "12th Grade",
    }[it]
  },
  getGradesForSelect: () => {
    return [
      // { name: "g_nursery", label: "Nursery" },
      { name: "g_pk", label: "Pre-K" },
      { name: "g_kg", label: "Kindergarten" },
      { name: "g_1", label: "1st Grade" },
      { name: "g_2", label: "2nd Grade" },
      { name: "g_3", label: "3rd Grade" },
      { name: "g_4", label: "4th Grade" },
      { name: "g_5", label: "5th Grade" },
      { name: "g_6", label: "6th Grade" },
      { name: "g_7", label: "7th Grade" },
      { name: "g_8", label: "8th Grade" },
      { name: "g_9", label: "9th Grade" },
      { name: "g_10", label: "10th Grade" },
      { name: "g_11", label: "11th Grade" },
      { name: "g_12", label: "12th Grade" },
    ]
  },
  getCountiesForSelect: () => {
    const arr = []
    for (const key in counties) arr.push({ name: key, label: (counties as any)[key] })
    return arr
  },
  getCountiesObject: (it: string) => {
    return (counties as any)[it]
  },
  getStatesForSelect: () => {
    return [
      {
        "label": "Alabama",
        "value": "AL"
      },
      {
        "label": "Alaska",
        "value": "AK"
      },
      {
        "label": "Arizona",
        "value": "AZ"
      },
      {
        "label": "Arkansas",
        "value": "AR"
      },
      {
        "label": "California",
        "value": "CA"
      },
      {
        "label": "Colorado",
        "value": "CO"
      },
      {
        "label": "Connecticut",
        "value": "CT"
      },
      {
        "label": "Delaware",
        "value": "DE"
      },
      {
        "label": "District Of Columbia",
        "value": "DC"
      },
      {
        "label": "Florida",
        "value": "FL"
      },
      {
        "label": "Georgia",
        "value": "GA"
      },
      {
        "label": "Hawaii",
        "value": "HI"
      },
      {
        "label": "Idaho",
        "value": "ID"
      },
      {
        "label": "Illinois",
        "value": "IL"
      },
      {
        "label": "Indiana",
        "value": "IN"
      },
      {
        "label": "Iowa",
        "value": "IA"
      },
      {
        "label": "Kansas",
        "value": "KS"
      },
      {
        "label": "Kentucky",
        "value": "KY"
      },
      {
        "label": "Louisiana",
        "value": "LA"
      },
      {
        "label": "Maine",
        "value": "ME"
      },
      {
        "label": "Maryland",
        "value": "MD"
      },
      {
        "label": "Massachusetts",
        "value": "MA"
      },
      {
        "label": "Michigan",
        "value": "MI"
      },
      {
        "label": "Minnesota",
        "value": "MN"
      },
      {
        "label": "Mississippi",
        "value": "MS"
      },
      {
        "label": "Missouri",
        "value": "MO"
      },
      {
        "label": "Montana",
        "value": "MT"
      },
      {
        "label": "Nebraska",
        "value": "NE"
      },
      {
        "label": "Nevada",
        "value": "NV"
      },
      {
        "label": "New Hampshire",
        "value": "NH"
      },
      {
        "label": "New Jersey",
        "value": "NJ"
      },
      {
        "label": "New Mexico",
        "value": "NM"
      },
      {
        "label": "New York",
        "value": "NY"
      },
      {
        "label": "North Carolina",
        "value": "NC"
      },
      {
        "label": "North Dakota",
        "value": "ND"
      },
      {
        "label": "Ohio",
        "value": "OH"
      },
      {
        "label": "Oklahoma",
        "value": "OK"
      },
      {
        "label": "Oregon",
        "value": "OR"
      },
      {
        "label": "Palau",
        "value": "PW"
      },
      {
        "label": "Pennsylvania",
        "value": "PA"
      },
      {
        "label": "Puerto Rico",
        "value": "PR"
      },
      {
        "label": "Rhode Island",
        "value": "RI"
      },
      {
        "label": "South Carolina",
        "value": "SC"
      },
      {
        "label": "South Dakota",
        "value": "SD"
      },
      {
        "label": "Tennessee",
        "value": "TN"
      },
      {
        "label": "Texas",
        "value": "TX"
      },
      {
        "label": "Utah",
        "value": "UT"
      },
      {
        "label": "Vermont",
        "value": "VT"
      },
      {
        "label": "Virgin Islands",
        "value": "VI"
      },
      {
        "label": "Virginia",
        "value": "VA"
      },
      {
        "label": "Washington",
        "value": "WA"
      },
      {
        "label": "West Virginia",
        "value": "WV"
      },
      {
        "label": "Wisconsin",
        "value": "WI"
      },
      {
        "label": "Wyoming",
        "value": "WY"
      }
    ]
  },
  getStates: () => {
    return [
      {
        "label": "Alabama",
        "id": "AL"
      },
      {
        "label": "Alaska",
        "id": "AK"
      },
      {
        "label": "American Samoa",
        "id": "AS"
      },
      {
        "label": "Arizona",
        "id": "AZ"
      },
      {
        "label": "Arkansas",
        "id": "AR"
      },
      {
        "label": "California",
        "id": "CA"
      },
      {
        "label": "Colorado",
        "id": "CO"
      },
      {
        "label": "Connecticut",
        "id": "CT"
      },
      {
        "label": "Delaware",
        "id": "DE"
      },
      {
        "label": "District Of Columbia",
        "id": "DC"
      },
      {
        "label": "Federated States Of Micronesia",
        "id": "FM"
      },
      {
        "label": "Florida",
        "id": "FL"
      },
      {
        "label": "Georgia",
        "id": "GA"
      },
      {
        "label": "Guam",
        "id": "GU"
      },
      {
        "label": "Hawaii",
        "id": "HI"
      },
      {
        "label": "Idaho",
        "id": "ID"
      },
      {
        "label": "Illinois",
        "id": "IL"
      },
      {
        "label": "Indiana",
        "id": "IN"
      },
      {
        "label": "Iowa",
        "id": "IA"
      },
      {
        "label": "Kansas",
        "id": "KS"
      },
      {
        "label": "Kentucky",
        "id": "KY"
      },
      {
        "label": "Louisiana",
        "id": "LA"
      },
      {
        "label": "Maine",
        "id": "ME"
      },
      {
        "label": "Marshall Islands",
        "id": "MH"
      },
      {
        "label": "Maryland",
        "id": "MD"
      },
      {
        "label": "Massachusetts",
        "id": "MA"
      },
      {
        "label": "Michigan",
        "id": "MI"
      },
      {
        "label": "Minnesota",
        "id": "MN"
      },
      {
        "label": "Mississippi",
        "id": "MS"
      },
      {
        "label": "Missouri",
        "id": "MO"
      },
      {
        "label": "Montana",
        "id": "MT"
      },
      {
        "label": "Nebraska",
        "id": "NE"
      },
      {
        "label": "Nevada",
        "id": "NV"
      },
      {
        "label": "New Hampshire",
        "id": "NH"
      },
      {
        "label": "New Jersey",
        "id": "NJ"
      },
      {
        "label": "New Mexico",
        "id": "NM"
      },
      {
        "label": "New York",
        "id": "NY"
      },
      {
        "label": "North Carolina",
        "id": "NC"
      },
      {
        "label": "North Dakota",
        "id": "ND"
      },
      {
        "label": "Northern Mariana Islands",
        "id": "MP"
      },
      {
        "label": "Ohio",
        "id": "OH"
      },
      {
        "label": "Oklahoma",
        "id": "OK"
      },
      {
        "label": "Oregon",
        "id": "OR"
      },
      {
        "label": "Palau",
        "id": "PW"
      },
      {
        "label": "Pennsylvania",
        "id": "PA"
      },
      {
        "label": "Puerto Rico",
        "id": "PR"
      },
      {
        "label": "Rhode Island",
        "id": "RI"
      },
      {
        "label": "South Carolina",
        "id": "SC"
      },
      {
        "label": "South Dakota",
        "id": "SD"
      },
      {
        "label": "Tennessee",
        "id": "TN"
      },
      {
        "label": "Texas",
        "id": "TX"
      },
      {
        "label": "Utah",
        "id": "UT"
      },
      {
        "label": "Vermont",
        "id": "VT"
      },
      {
        "label": "Virgin Islands",
        "id": "VI"
      },
      {
        "label": "Virginia",
        "id": "VA"
      },
      {
        "label": "Washington",
        "id": "WA"
      },
      {
        "label": "West Virginia",
        "id": "WV"
      },
      {
        "label": "Wisconsin",
        "id": "WI"
      },
      {
        "label": "Wyoming",
        "id": "WY"
      }
    ]
  }
}

export default general;