import './SchoolAccessTag.scss';

export default function SchoolAccessTag({ access = 'unknown' }) {
  const accessMap: any = {
    'PUBLIC': 'Public',
    'PRIVATE': 'Private',
    'UNKNOWN': 'Unknown'
  }
  access = access || 'UNKNOWN'
  return <>
    {
      <span className={`access-tag access-tag-${access.toLowerCase()}`}>
        { accessMap[access] }
      </span>
    }
  </>
}
