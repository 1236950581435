import { Card } from '@aws-amplify/ui-react';
import React, { useContext } from 'react';
import { HiBadgeCheck } from 'react-icons/hi';
import { GlobalContext, GlobalContextProps } from '../global-context';
import general from '../utils/general';

export function Profile(props: any) {

  const globalContext = useContext(GlobalContext);
  general.setTitle(document, 'Profile', globalContext);

  return <GlobalContext.Consumer>
    {
      (globalContextProps: GlobalContextProps) => <>
        <div className="content content-1000">
          <h2>Profile</h2>
          <Card variation='outlined' style={{fontSize: 14}}>
            <div style={{marginBottom: '10px'}}><strong style={{width: 110, display: 'inline-block'}}>Name:</strong> { globalContextProps.authInfo?.userAttributes?.given_name }</div>
            <div style={{marginBottom: '10px'}}><strong style={{width: 110, display: 'inline-block'}}>Last Name:</strong> { globalContextProps.authInfo?.userAttributes?.family_name }</div>
            <div style={{marginBottom: '10px'}}><strong style={{width: 110, display: 'inline-block'}}>Email:</strong> { globalContextProps.authInfo?.userAttributes?.email }</div>
            <div><strong style={{width: 110, display: 'inline-block'}}>Email Verified:</strong> {
              globalContextProps.authInfo?.userAttributes?.email_verified === 'true' ?
              <>
                <span>Yes</span>
                <HiBadgeCheck size={20} color='var(--amplify-colors-green-80)' style={{ transform: 'translate(3px, 4px)' }} />
              </> :
              <>
                <span>No</span>
              </>
            }</div>
          </Card>
        </div>
      </>
    }
  </GlobalContext.Consumer>
}
