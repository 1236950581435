import { Link as AmplifyLink, Button, Card, Flex, Loader, Pagination, Text, TextField } from '@aws-amplify/ui-react';
import { useContext, useEffect, useRef, useState } from 'react';
import ReactModal from 'react-modal';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import NA from '../custom-components/NA';
import TitleCategoryTag from '../custom-components/TitleCategoryTag';
import { GlobalContext, GlobalContextProps } from '../global-context';
import { ContactsSearchParams } from '../utils/ContactsSearchParams';
import general from '../utils/general';
import { TbTrash } from 'react-icons/tb';
import EmailAddress from '../custom-components/EmailAddress';
import InstitutionTypeTag from '../custom-components/InstitutionTypeTag';
import { post } from 'aws-amplify/api';
import 'react-querybuilder/dist/query-builder.css';
import QueryEditor from '../custom-components/QueryEditor';
import ContactList from '../custom-components/ContactList';

const PAGE_SIZE = 25

export function Contacts(props: any) {

  const globalContext = useContext(GlobalContext);
  general.setTitle(document, 'Contacts', globalContext);

  const location = useLocation();
  const navigate = useNavigate();
  
  // const [isHubspotSyncModalOpened, setIsHubspotSyncModalOpened] = useState(false);
  // const [isSyncingToHubspot, setIsSyncingToHubspot] = useState(false);
  // const [hasSyncedToHubspot, setHasSyncedToHubspot] = useState(false);
  // const [isLoadingContactsLocations, setIsLoadingContactsLocations] = useState(false);
  // const [contactsLocationsSearchResponse, setContactsLocationsSearchResponse] = useState<any>(null);
  // const [availableTitles, setAvailableTitles] = useState<any[]>();
  const [isExportingToCsv, setIsExportingToCsv] = useState(false);
  const [isSaveSearchQueryModalOpened, setIsSaveSearchQueryModalOpened] = useState(false);
  const [isSavedSearchQueriesDrawerOpened, setIsSavedSearchQueriesDrawerOpened] = useState(false);
  const [isContactDetailDrawerOpened, setIsContactDetailDrawerOpened] = useState(false);
  const [selectedContact] = useState<any>(null);
  const [isSavingQuery, setIsSavingQuery] = useState(false);
  const [searchQueryName, setSearchQueryName] = useState<string>();
  const [isLoadingContacts, setIsLoadingContacts] = useState(false);
  const [contactsSearchResponse, setContactsSearchResponse] = useState<any>(null);
  const [currentSearchParams, setCurrentSearchParams] = useState<ContactsSearchParams>();
  const [newSearchParams, setNewSearchParams] = useState<ContactsSearchParams>();
  const [searchQueries, setSearchQueries] = useState<any[]>();
  const [teamTags, setTeamTags] = useState<string[]>([]);
  const contactListRef = useRef();

  async function loadContacts() {
    setIsLoadingContacts(true)
    const headers = await general.getAuthorizationHeaders()
    const body = {
      action: 'DB_SEARCH',
      data: {
        pageSize: PAGE_SIZE,
        entity: 'contacts'
      }
    }
    Object.assign(body.data, currentSearchParams?.toBody())
    try {
      const postOperation = post({
        apiName: 'apiMain',
        path: '/data',
        options: {
          headers,
          body
        }
      });
      const response = await postOperation.response
      const bodyJson = await response.body.json() as any
      setContactsSearchResponse(bodyJson)
    } catch (err) {
      toast.warn(`Something went wrong loading contacts${(err as any)?.message ? `. Reason: ${(err as any)?.message}` : ''}`, { autoClose: 10000 })
    } finally {
      setIsLoadingContacts(false)
    }
  }

  // async function loadContactsLocations() {
  //   setIsLoadingContactsLocations(true)
  //   const headers = await general.getAuthorizationHeaders()
  //   const body = {
  //     action: 'DB_SEARCH',
  //     data: {
  //       pageSize: PAGE_SIZE,
  //       entity: 'contacts-locations'
  //     }
  //   }
  //   Object.assign(body, currentSearchParams?.toBody())
  //   try {
  //     const postOperation = post({
  //       apiName: 'apiMain',
  //       path: '/data',
  //       options: {
  //         headers,
  //         body
  //       }
  //     });
  //     const response = await postOperation.response
  //     const bodyJson = await response.body.json() as any
  //     setContactsLocationsSearchResponse(bodyJson)
  //   } catch (err) {
  //     toast.warn('Failed to load contacts locations', { autoClose: false })
  //   } finally {
  //     setIsLoadingContactsLocations(false)
  //   }
  // }

  // async function syncToHubspot(globalContextProps: GlobalContextProps) {
  //   setIsSyncingToHubspot(true)
  //   try {
  //     const headers = await general.getAuthorizationHeaders();
  //     const body = { contacts: Object.entries(selectedContacts).map(it => ({ uid: it[0], ...it[1]._source })) };
  //     post({
  //       apiName: 'apiHubspot',
  //       path: '/contacts',
  //       options: {
  //         headers,
  //         body
  //       }
  //     });
  //     toast.success(`Successfully synced contacts to Hubspot`)
  //     setHasSyncedToHubspot(true)
  //   } catch (error) {
  //     toast.warn('Something went wrong syncing contacts to a Hubspot, please try again')
  //     console.error(error)
  //   } finally {
  //     setIsSyncingToHubspot(false)
  //   }
  // }

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const params = {
      page: general.getQueryParamValueOrNull(queryParams, 'page') || 1,
      sortBy: general.getQueryParamValueOrNull(queryParams, 'sortBy') || 'email',
      sortDir: general.getQueryParamValueOrNull(queryParams, 'sortDir') || 'desc',
      q: general.getQueryParamValueOrNull(queryParams, 'q') || '',
    }
    setCurrentSearchParams(new ContactsSearchParams(params))
    setNewSearchParams(new ContactsSearchParams(params))
  }, [location.search]);

  useEffect(() => {
    if (!currentSearchParams) return
    loadContacts()
    // loadContactsLocations()
    getSearchQueries()
    getTeamTags()
  }, [currentSearchParams]);

  async function getSearchQueries() {
    const headers = await general.getAuthorizationHeaders()
    const body: any = {
      action: 'LIST_QUERIES',
      data: { entity: 'QUERY_CONTACT' }
    }
    const getTeamOperation = post({
      apiName: 'apiMain',
      path: '/data',
      options: { headers, body }
    });
    const res = await getTeamOperation.response
    const data = await res.body.json() as any
    setSearchQueries(data?.data?.queries)
  }

  async function getTeamTags() {
    const headers = await general.getAuthorizationHeaders()
    const body: any = {
      action: 'LIST_TAGS',
      data: {}
    }
    const operation = post({
      apiName: 'apiMain',
      path: '/data',
      options: { headers, body }
    });
    const res = await operation.response
    const data = await res.body.json() as any
    setTeamTags(data?.data?.tags)
  }

  async function deleteSearchQuery(searchQuery: any) {
    try {
      const headers = await general.getAuthorizationHeaders()
      const body: any = {
        action: 'DELETE_QUERY',
        data: {
          entity: 'QUERY_CONTACT',
          id: searchQuery.id
        }
      }
      const operation = post({
        apiName: 'apiMain',
        path: '/data',
        options: { headers, body }
      });
      await operation.response
      
      setSearchQueries(searchQueries?.filter(it => it.id !== searchQuery.id))

    } catch (err) {
      toast.warn('Deleting search query failed')
    }
  }

  function onSort(sortBy: string, sortDir: string) {
    const sortedSearchParams = new ContactsSearchParams({
      ...newSearchParams?.raw(),
      ...{ sortBy, sortDir }
    })
    navigate(`?${sortedSearchParams?.serialize('1')}`);
  }

  async function onExportToCsv(ids?: any[]) {
    setIsExportingToCsv(true)
    try {
      const headers = await general.getAuthorizationHeaders();
      const body: any = {
        action: 'DB_SEARCH',
        data: { entity: 'contacts', action: 'export-csv' }
      }

      if (Array.isArray(ids)) body.data.ids = ids

      Object.assign(body.data, currentSearchParams?.toBody())
      const postOperation = post({
        apiName: 'apiMain',
        path: '/data',
        options: {
          headers,
          body
        }
      });
      const response = await postOperation.response
      const bodyJson = await response.body.json() as any

      await general.downloadUrl(bodyJson?.data?.downloadUrl, bodyJson?.data?.fileName)
    } catch (error: any) {
      toast.warn(general.getErrorMessage('Something went wrong exporting to a CSV', error))
      console.error(error)
    } finally {
      setIsExportingToCsv(false)
    }
  }

  async function saveSearchQuery() {
    try {
      setIsSavingQuery(true)

      const headers = await general.getAuthorizationHeaders()
      const body: any = {
        action: 'CREATE_QUERY',
        data: {
          entity: 'QUERY_CONTACT',
          name: searchQueryName,
          query: newSearchParams?.serialize()
        }
      }
      const operation = post({
        apiName: 'apiMain',
        path: '/data',
        options: { headers, body }
      });
      const response = await operation.response
      const bodyJson = await response.body.json() as any

      setSearchQueries([
        bodyJson.data,
        ...(searchQueries || [])
      ])
      
      setIsSaveSearchQueryModalOpened(false)
      
      toast.success('Successfully saved the query')
    } catch (e) {
      toast.warn('Something went wrong saving the query')
    } finally {
      setIsSavingQuery(false)
    }
  }

  function handlePageChange(newPageNumber: number | undefined) {
    if (!newPageNumber) return
    navigate(`?${newSearchParams?.serialize(`${newPageNumber}`)}`);
  }

  function handleOnSearch() {
    if ((contactListRef?.current as any)?.clearSelectedContacts) {
      (contactListRef?.current as any)?.clearSelectedContacts();
    }
    navigate(`?${newSearchParams?.serialize('1')}`);
  }

  function onSaveTag(row: any, savedTag: string) {
    if (!Array.isArray(row.tags)) row.tags = []
    row.tags.push(savedTag)
    setContactsSearchResponse((prevState: any) => ({
      ...contactsSearchResponse
    }))
    if (!teamTags.includes(savedTag)) {
      setTeamTags((prevState: any) => [...prevState, savedTag])
    }
  }

  function onDeleteTag(row: any, deletedTag: string) {
    const index = row.tags.findIndex((it: string) => it === deletedTag)
    row.tags.splice(index, 1)
    setContactsSearchResponse((prevState: any) => ({
      ...contactsSearchResponse
    }))
  }

  return <GlobalContext.Consumer>
    {
      (globalContextProps: GlobalContextProps) => <>
        <div className="content content-1000">
          <div className="main-card">

            <Flex className='results-row results-header' justifyContent='flex-end' alignItems='baseline'>
              {/* <div className="desc-4"><strong>Contacts</strong></div> */}
              <Button
                className='tiny'
                onClick={() => setIsSavedSearchQueriesDrawerOpened(true)}
              >Show Saved Queries</Button>
            </Flex>

            <div style={{ padding: 15 }}>
              {
                currentSearchParams && teamTags
                  ? <QueryEditor
                      initialQuery={currentSearchParams?.q.serialize()}
                      supportedFields={globalContextProps.environment.entities.contact.queryEditorSupportedFields}
                      teamTags={teamTags}
                      type='contacts'
                      onChange={(query: string | null) => {
                        setNewSearchParams(prevState => new ContactsSearchParams({
                          ...prevState?.raw(),
                          ...{ q: query }
                        }))
                      }}
                    />
                  : <></>
              }
              <Flex justifyContent='flex-end' marginTop={30} gap={5}>
                <Button
                  disabled={searchQueries && searchQueries?.length >= 30}
                  size='small'
                  onClick={() => setIsSaveSearchQueryModalOpened(true)}
                  loadingText="Saving"
                >
                  Save Query</Button>
                <Button
                  variation='primary'
                  size='small'
                  isDisabled={(currentSearchParams?.serialized === newSearchParams?.serialized) || newSearchParams?.q.value === 'eyJydWxlcyI6W10sImNvbWJpbmF0b3IiOiJhbmQiLCJub3QiOmZhbHNlfQ=='}
                  isLoading={isLoadingContacts}
                  onClick={handleOnSearch}
                  loadingText="Searching"
                >Search</Button>
              </Flex>
            </div>
          </div>
        </div>
        <div className="content content-1000">
          <ContactList
            contactsSearchResponse={contactsSearchResponse}
            isLoadingContacts={isLoadingContacts}
            isExportingToCsv={isExportingToCsv}
            onSort={onSort}
            onExportToCsv={onExportToCsv}
            onDeleteTag={onDeleteTag}
            onSaveTag={onSaveTag}
            showTags={false}
            showFavorite={true}
            ref={contactListRef}
          />
          {
            !isLoadingContacts &&
              <Flex
                marginTop={30}
                marginBottom={50}
                justifyContent='center'
              >
                <Pagination
                  currentPage={parseInt(currentSearchParams?.page.value)}
                  totalPages={Math.ceil(contactsSearchResponse?.data?.hits?.total?.value / PAGE_SIZE)}
                  siblingCount={1}
                  onChange={handlePageChange}
                />
              </Flex>
          }
        </div>
        {/* <ReactModal
          isOpen={isHubspotSyncModalOpened}
          onAfterClose={() => {
            setHasSyncedToHubspot(false)
          }}
          shouldCloseOnEsc={true}
          shouldCloseOnOverlayClick={true}
          onRequestClose={() => setIsHubspotSyncModalOpened(false)}
          style={general.getModalDefaults()}
          parentSelector={general.getModalRoot}
        >
          <Heading marginBottom={20}>Sync following { selectedContactsCount } contact{ selectedContactsCount === 1 ? '' : 's' } to Hubspot</Heading>
          <Table highlightOnHover={false} size="small">
            <TableBody>
              {
                general.getTrueKeys(selectedContacts).map(item =>
                  <TableRow key={item[1]['_id']}>
                    <TableCell>
                      { item[1]._source.name }
                    </TableCell>
                    <TableCell
                      align='right'
                      textAlign='right'
                    >
                      {
                        hasSyncedToHubspot ?
                          <span style={{ color: 'var(--amplify-colors-green-80)' }}>
                            <HiBadgeCheck size={16} style={{ transform: 'translate(0, 3px)', marginRight: 5 }} />
                            <strong>Synced</strong>
                          </span> :
                          null
                      }
                    </TableCell>
                  </TableRow>
                )
              }
            </TableBody>
          </Table>
          <Flex justifyContent='flex-end' gap={10}>
            <Button
              marginTop={20}
              size='small'
              onClick={() => setIsHubspotSyncModalOpened(false)}
            >
              Close
            </Button>
            <Button
              marginTop={20}
              size='small'
              variation='primary'
              disabled={isSyncingToHubspot || hasSyncedToHubspot}
              onClick={syncToHubspot.bind(null, globalContextProps)}
            >
              { isSyncingToHubspot ? 'Syncing...' : 'Sync' }
            </Button>
          </Flex>
        </ReactModal> */}
        <ReactModal
          isOpen={isSaveSearchQueryModalOpened}
          shouldCloseOnEsc={true}
          shouldCloseOnOverlayClick={true}
          onRequestClose={() => setIsSaveSearchQueryModalOpened(false)}
          style={general.getModalDefaults()}
          parentSelector={general.getModalRoot}
        >
          <h4 style={{ marginTop: 0 }}>Save Query</h4>
          <TextField
            placeholder=""
            label="Name"
            onChange={event => {
              setSearchQueryName(event?.target?.value)
            }}
          />
          <Flex justifyContent='flex-end' alignItems='center'>
            { isSavingQuery && <Loader style={{ transform: 'translateY(10px)' }} /> }
            <Button
              style={{ marginTop: 20 }}
              variation='primary'
              size='small'
              isDisabled={isSavingQuery}
              onClick={() => {
                saveSearchQuery()
              }}
            >Save</Button>
          </Flex>
        </ReactModal>
        <ReactModal
          isOpen={isSavedSearchQueriesDrawerOpened}
          shouldCloseOnEsc={true}
          shouldCloseOnOverlayClick={true}
          onRequestClose={() => setIsSavedSearchQueriesDrawerOpened(false)}
          style={general.getSliderDefaults()}
          closeTimeoutMS={200}
          parentSelector={general.getModalRoot}
        >
          <div style={{
            padding: '0 15px'
          }}>
            <h4>Saved Queries</h4>
          </div>
          <Flex direction="column" style={{ gap: 0, marginTop: 20 }}>
            {
              !searchQueries?.length ?
                <div className="nothing-found">No saved queries found</div> :
                searchQueries?.map(it =>
                  <div key={it.id} className="custom-item-1">
                    <div>
                      <Link to={`/directory/contacts?${it.query}`}>{ it.name }</Link>
                    </div>
                    <div>
                      <Button
                        size='small'
                        variation='link'
                        title='Delete'
                        onClick={() => {
                          deleteSearchQuery(it)
                        }}
                      >
                        <TbTrash />
                      </Button>
                    </div>
                  </div>
                )
            }
          </Flex>
        </ReactModal>
        <ReactModal
          isOpen={isContactDetailDrawerOpened}
          shouldCloseOnEsc={true}
          shouldCloseOnOverlayClick={true}
          onRequestClose={() => setIsContactDetailDrawerOpened(false)}
          style={general.getSliderDefaults({ width: '500px' })}
          closeTimeoutMS={200}
          parentSelector={general.getModalRoot}
        >
          {
            selectedContact ?
              <div style={{ padding: '15px' }}>
                <Flex alignItems="center" style={{ marginBottom: 20 }}>
                  <div className={`avatar avatar-small avatar-${general.getCSSFromId(selectedContact._id)}`}>
                    { general.getInitials(selectedContact._source?.name) }
                  </div>
                  <h3>{ selectedContact._source?.name || '<Contact\'s name not available>' }</h3>
                </Flex>
                <Card variation='outlined' style={{fontSize: 14}}>
                  <div style={{marginBottom: '10px'}}><strong style={{ width: 100, display: 'inline-block' }}>Job title: </strong>{ selectedContact._source['title'] ? <>{ selectedContact._source['title'] } <TitleCategoryTag titleCategory={selectedContact._source['titleCategory']} /></> : <NA /> }</div>
                  <div style={{marginBottom: '10px'}}><strong style={{ width: 100, display: 'inline-block' }}>Email: </strong><EmailAddress email={selectedContact._source['email']} emailDomainCount={selectedContact._source['emailDomainCount']} emailVerificationStatus={selectedContact._source['emailVerificationStatus']} emailLastVerifiedAt={selectedContact._source['emailLastVerifiedAt']} /></div>
                  <div><strong style={{ width: 100, display: 'inline-block' }}>Phone: </strong>{ selectedContact._source['phone'] || <NA /> }</div>
                </Card>
                <Text
                  variation="primary"
                  as="p"
                  fontWeight={400}
                  fontStyle="italic"
                  fontSize='small'
                  style={{
                    marginTop: 20
                  }}
                >
                  Associated with { selectedContact._source?.belongsTo?.length || 'these' } institution{ selectedContact._source?.belongsTo?.length === 1 ? '' : 's' }
                </Text>
                {
                  general.deduplicateArrayByKey(selectedContact._source.belongsTo, 'id')?.sort((a: any, b: any) => a?.entityType > b?.entityType ? 1 : 0).map((item: any) =>
                    <Card variation='outlined' style={{fontSize: 14, marginTop: 5}} key={item.id}>
                      { ['SCHOOL', 'HOSPITAL'].includes(item?.entityType) && <><InstitutionTypeTag category={item?.entityType} /> <Link to={`/directory/${globalContext.environment.entities.childInstitution.urlSegment}/${item?.id || ''}`}>{ item?.name }</Link>, { general.getStateName(item?.state) }</> }
                      { ['DISTRICT', 'IDN'].includes(item?.entityType) && <><InstitutionTypeTag category={item?.entityType} /> <Link to={`/directory/${globalContext.environment.entities.parentInstitution.urlSegment}/${item?.id || ''}`}>{ item?.name }</Link>, { general.getStateName(item?.state) }</> }
                    </Card>
                  )
                }
                <Text
                  variation="primary"
                  as="p"
                  fontWeight={400}
                  fontStyle="italic"
                  fontSize='small'
                  style={{
                    marginTop: 20
                  }}
                >
                  Contact found on { selectedContact._source?.foundOnUrls?.length || 'these' } URL{ selectedContact._source?.foundOnUrls?.length === 1 ? '' : 's' }
                </Text>
                <Card variation='outlined' style={{fontSize: 14, marginTop: 5}} >
                  {
                    selectedContact._source.foundOnUrls.map((item: any) =>
                      <div key={item.url}>
                        <AmplifyLink
                          target='_blank'
                          href={ item?.url }
                        >
                          { general.ellipsisInMiddle(item?.url) }
                        </AmplifyLink>
                      </div>
                    )
                  }
                </Card>
                {/* {
                  currentSearchParams?.fulltext.value ?
                    <>
                      <Text
                        variation="primary"
                        as="p"
                        fontWeight={400}
                        fontStyle="italic"
                        fontSize='small'
                        style={{
                          marginTop: 20
                        }}
                      >
                        Phrase '{ currentSearchParams?.fulltext.value }' found on these URLs
                      </Text>
                      <Card variation='outlined' style={{fontSize: 14, marginTop: 5}} >
                        {
                          selectedContact._source?.fulltextMatchedUrls?.map((item: any) =>
                            <div key={item}>
                              <AmplifyLink
                                target='_blank'
                                href={ item }
                              >
                                { general.ellipsisInMiddle(item) }
                              </AmplifyLink>
                            </div>
                          )
                        }
                      </Card>
                    </> : <></>
                } */}
              </div> :
              <NA text='No contact selected' />
          }
        </ReactModal>
      </>
    }
  </GlobalContext.Consumer>
}
