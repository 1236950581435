import { SortOptions } from "./utils/CustomTypes"

export enum Entity {
  CONTACT = 'CONTACT',
  SCHOOL = 'SCHOOL',
  DISTRICT = 'DISTRICT',
  HOSPITAL = 'HOSPITAL',
  IDN = 'IDN',
}

export type CustomEnvironment = {
  name: string,
  urlApp: string,
  urlWeb: string,
  urlWebName: string,
  urlPrivacy: string,
  urlTerms: string,
  logo: string,
  logoWidth: number,
  entities: {
    contact: {
      defaultQueryString: string,
      queryEditorSupportedFields: string[],
    }
    parentInstitution: {
      type: Entity,
      name: string,
      namePlural: string,
      urlSegment: string,
      defaultQueryString: string,
      sortOptions: SortOptions[],
      queryEditorSupportedFields: string[],
    },
    childInstitution: {
      type: Entity,
      name: string,
      namePlural: string,
      urlSegment: string,
      defaultQueryString: string,
      sortOptions: SortOptions[],
      queryEditorSupportedFields: string[],
    },
  },
  flags: {
    shouldEnableServiceAgenciesPage: boolean,
    shouldEnableProspectorPage: boolean,
    shouldEnableNewHiresPage: boolean,
  },
}

export function getFlavor(): string {
  try {
    return process.env.REACT_APP_FLAVOR || 'EDULEAD'
  } catch {
    return 'EDULEAD'
  }
}

export function getEnvironment(): CustomEnvironment {
  const flavor = getFlavor()
  console.log('Getting environment')
  return environment?.[flavor] || environment['EDULEAD']
}

const environment: { [key: string]: CustomEnvironment } = {
  EDULEAD: {
    name: 'Edulead',
    urlApp: 'https://app.getedulead.com',
    urlWeb: 'https://www.getedulead.com',
    urlWebName: 'getedulead.com',
    urlPrivacy: 'https://getedulead.com/privacy',
    urlTerms: 'https://getedulead.com/terms',
    logo: 'logo-edulead.svg',
    logoWidth: 121,
    entities: {
      contact: {
        defaultQueryString: '?q=eyJydWxlcyI6W3siZmllbGQiOiJ0aXRsZSIsIm9wZXJhdG9yIjoiPSIsInZhbHVlIjoiU3VwZXJpbnRlbmRlbnQsIERpcmVjdG9yIG9mIFRlY2hub2xvZ3ksIENoaWVmIEZpbmFuY2lhbCBPZmZpY2VyIn1dLCJjb21iaW5hdG9yIjoiYW5kIiwibm90IjpmYWxzZX0%3D&page=1&sortBy=email&sortDir=asc',
        queryEditorSupportedFields: [
          'zip',
          'ncesId',
          'districtRevTotal',
          'studentTchrRatio',
          'studentsTotal',
          'stfTotal',
          'countyId',
          'fulltext',
          'grade',
          'tag',
          'name',
          'title',
          'titleCategory',
          'email',
          'emailVerificationStatus',
          'phone',
          'shapes',
          'state',
          'institutionType',
          'religiousAffiliation'
        ]
      },
      parentInstitution: {
        type: Entity.DISTRICT,
        name: 'District',
        namePlural: 'Districts',
        urlSegment: 'districts',
        defaultQueryString: '?q=eyJydWxlcyI6W3siZmllbGQiOiJzdGF0ZSIsIm9wZXJhdG9yIjoiPSIsInZhbHVlU291cmNlIjoidmFsdWUiLCJ2YWx1ZSI6IkNBLFRYLEZMLFBBIn0seyJmaWVsZCI6ImRpc3RyaWN0UmV2VG90YWwiLCJvcGVyYXRvciI6Ij4iLCJ2YWx1ZVNvdXJjZSI6InZhbHVlIiwidmFsdWUiOiI1MDAwMDAwMCJ9XSwiY29tYmluYXRvciI6ImFuZCIsIm5vdCI6ZmFsc2V9&page=1&sortBy=name&sortDir=asc',
        sortOptions: [{
          sortBy: 'name',
          sortDir: 'asc',
          name: 'By name, A to Z'
        }, {
          sortBy: 'name',
          sortDir: 'desc',
          name: 'By name, Z to A'
        }, {
          sortBy: 'locState',
          sortDir: 'asc',
          name: 'By state, A to Z'
        }, {
          sortBy: 'locState',
          sortDir: 'desc',
          name: 'By state, Z to A'
        }, {
          sortBy: 'fsclRevTotal',
          sortDir: 'desc',
          name: 'By district annual revenue, High to Low'
        }, {
          sortBy: 'studentsTotal',
          sortDir: 'desc',
          name: 'By # of students, High to Low'
        }, {
          sortBy: 'totalSchools',
          sortDir: 'desc',
          name: 'By # of schools, High to Low'
        }],
        queryEditorSupportedFields: [
          'name',
          'state',
          'zip',
          'shapes',
          'phone',
          'fax',
          'districtRevTotal',
          'studentsTotal',
          'totalSchools',
          'fulltext'
        ]
      },
      childInstitution: {
        type: Entity.SCHOOL,
        name: 'School',
        namePlural: 'Schools',
        urlSegment: 'schools',
        defaultQueryString: '?q=eyJydWxlcyI6W3siZmllbGQiOiJzdGF0ZSIsIm9wZXJhdG9yIjoiPSIsInZhbHVlU291cmNlIjoidmFsdWUiLCJ2YWx1ZSI6IkNBLFRYLEZMLFBBIn0seyJmaWVsZCI6ImRpc3RyaWN0UmV2VG90YWwiLCJvcGVyYXRvciI6Ij4iLCJ2YWx1ZVNvdXJjZSI6InZhbHVlIiwidmFsdWUiOiI1MDAwMDAwMDAifV0sImNvbWJpbmF0b3IiOiJhbmQiLCJub3QiOmZhbHNlfQ%3D%3D&page=1&sortBy=locState&sortDir=asc',
        sortOptions: [{
          sortBy: 'name',
          sortDir: 'asc',
          name: 'By name, A to Z'
        }, {
          sortBy: 'name',
          sortDir: 'desc',
          name: 'By name, Z to A'
        }, {
          sortBy: 'locState',
          sortDir: 'asc',
          name: 'By state, A to Z'
        }, {
          sortBy: 'locState',
          sortDir: 'desc',
          name: 'By state, Z to A'
        }, {
          sortBy: 'dsFsclRevTotal',
          sortDir: 'desc',
          name: 'By district annual revenue, High to Low'
        }],
        queryEditorSupportedFields: [
          'name',
          'state',
          'zip',
          'shapes',
          'phone',
          'fax',
          'districtRevTotal',
          'schoolReligiousAffiliation',
          'schoolAccess',
          'schoolType',
          'grade',
          'fulltext'
        ]
      }
    },
    flags: {
      shouldEnableServiceAgenciesPage: true,
      shouldEnableProspectorPage: true,
      shouldEnableNewHiresPage: true,
    },
  },
  HEALTH: {
    name: 'ClearNode',
    urlApp: 'https://app.clearnode.io',
    urlWeb: 'https://www.clearnode.io',
    urlWebName: 'clearnode.io',
    urlPrivacy: 'https://clearnode.io/privacy',
    urlTerms: 'https://clearnode.io/terms',
    logo: 'logo-clearnode.svg',
    logoWidth: 142,
    entities: {
      contact: {
        defaultQueryString: '?q=eyJydWxlcyI6W3siZmllbGQiOiJ0aXRsZSIsIm9wZXJhdG9yIjoiPSIsInZhbHVlIjoiQ2hpZWYgRmluYW5jaWFsIE9mZmljZXIsIFByZXNpZGVudCJ9XSwiY29tYmluYXRvciI6ImFuZCIsIm5vdCI6ZmFsc2V9&page=1&sortBy=email&sortDir=asc',
        queryEditorSupportedFields: [
          'zip',
          'countyId',
          'fulltext',
          'name',
          'title',
          'titleCategory',
          'email',
          'emailVerificationStatus',
          'phone',
          'shapes',
          'state',
          'institutionType'
        ]
      },
      parentInstitution: {
        type: Entity.IDN,
        name: 'IDN',
        namePlural: 'IDNs',
        urlSegment: 'idns',
        defaultQueryString: '?q=eyJydWxlcyI6W10sImNvbWJpbmF0b3IiOiJhbmQiLCJub3QiOmZhbHNlfQ==&page=1&sortBy=name&sortDir=asc',
        sortOptions: [{
          sortBy: 'name',
          sortDir: 'asc',
          name: 'By name, A to Z'
        }, {
          sortBy: 'name',
          sortDir: 'desc',
          name: 'By name, Z to A'
        }],
        queryEditorSupportedFields: [
          'name'
        ]
      },
      childInstitution: {
        type: Entity.HOSPITAL,
        name: 'Hospital',
        namePlural: 'Hospitals',
        urlSegment: 'hospitals',
        defaultQueryString: '?q=eyJydWxlcyI6W3siZmllbGQiOiJzdGF0ZSIsIm9wZXJhdG9yIjoiPSIsInZhbHVlU291cmNlIjoidmFsdWUiLCJ2YWx1ZSI6Ik5DIn1dLCJjb21iaW5hdG9yIjoiYW5kIiwibm90IjpmYWxzZX0%3D&page=1&sortBy=name&sortDir=asc',
        sortOptions: [{
          sortBy: 'name',
          sortDir: 'asc',
          name: 'By name, A to Z'
        }, {
          sortBy: 'name',
          sortDir: 'desc',
          name: 'By name, Z to A'
        }, {
          sortBy: 'locState',
          sortDir: 'asc',
          name: 'By state, A to Z'
        }, {
          sortBy: 'locState',
          sortDir: 'desc',
          name: 'By state, Z to A'
        }, {
          sortBy: 'dsFsclRevTotal',
          sortDir: 'desc',
          name: 'By district annual revenue, High to Low'
        }],
        queryEditorSupportedFields: [
          'name',
          'state'
        ]
      }
    },
    flags: {
      shouldEnableServiceAgenciesPage: false,
      shouldEnableProspectorPage: false,
      shouldEnableNewHiresPage: false,
    },
  }
}
