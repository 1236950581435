import { Link as AmplifyLink } from '@aws-amplify/ui-react';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { AiOutlineLink } from 'react-icons/ai';
import general from '../../utils/general';
import Currency from './../Currency';
import { GlobalContext } from '../../global-context';

export function TableRowHeaderDistrict() {
  return <>
    <div className='results-subheader' style={{ justifyContent: 'flex-end' }}>
      <div style={{ flex: 1, paddingLeft: 40 }}>Name</div>
      <div style={{ width: '150px' }}>District revenue</div>
      <div style={{ width: '120px' }}># of students</div>
      <div style={{ width: '140px' }}># of schools</div>
    </div>
  </>
}

export function TableRowDistrict({
  row,
}: {
  row: any
}) {
  const globalContext = useContext(GlobalContext);

  return <>
    <div className='results-row-content'>
      <div className="results-row-main-row">
        <div style={{ flex: 1.8 }}>
          <div>
            <Link to={`/directory/${globalContext.environment.entities.parentInstitution.urlSegment}/${row._id}`} className='main-table-link'>{ general.trim(row._source['name'], 50) }</Link>
            <div style={{ marginTop: 5 }}>
              { [row._source['locCity'] || null, general.getStateName(row._source['locState']) || null].filter(it => !!it).join(', ') }
            </div>
          </div>
          {
            (row._source?.phone || row._source?.fax) && <div style={{ marginTop: 8 }} className='desc-2'>
              {
                row._source?.phone && <span>
                  Phone { row._source?.phone }
                </span>
              }
              {
                (row._source?.phone && row._source?.fax) && <span>, </span>
              }
              {
                row._source?.fax && <span>
                  Fax { row._source?.fax }
                </span>
              }
            </div>
          }
          {/* <div style={{ display: 'flex', marginTop: 5, marginBottom: -8 }}>
            {
              row._source?.fulltextMatchedUrls?.length ?
                <div style={{ marginRight: 10 }}>
                  <span
                    className='tiny-button nonclickable-row'
                    onClick={() => {
                      const isExpanded = expanedRows.fulltext[row._id]
                      setExpandedRows({
                        ...expanedRows,
                        fulltext: {
                          ...expanedRows.fulltext,
                          [row._id]: !isExpanded
                        },
                      })
                    }}
                  >
                    { row._source?.fulltextMatchedUrls?.length } URL{ row._source?.fulltextMatchedUrls?.length === 1 ? '' : 's' } matching fulltext search { expanedRows.fulltext[row._id] ? <BiChevronUp /> : <BiChevronDown /> }
                  </span>
                </div> : <></>
            }
          </div> */}
        </div>
        <div style={{ width: '150px' }}>
          <Currency amount={ row._source['fsclRevTotal'] } />
        </div>
        <div style={{ width: '120px' }}>
          { row._source['studentsTotal'] }
        </div>
        <div style={{ width: '120px' }}>
          { row._source['totalSchools'] }
        </div>
        <div style={{ width: '20px' }}>
          {
            row._source['website'] ?
              <AmplifyLink
                target='_blank'
                href={row._source['website']}
                title={row._source['website']}
                style={{ width: 18, height: 18, display: 'block' }}
                data-tooltip-id="main-tooltip"
                data-tooltip-content={ row._source['website'] }
              >
                <AiOutlineLink size={18} />
              </AmplifyLink> :
              <></>
          }
        </div>
      </div>
      {/* <div>
        {
          expanedRows.fulltext[row._id] ?
            <div style={{ marginTop: 10 }}>
              {
                row._source?.fulltextMatchedUrls.map((it: any) => (
                  <div key={it} style={{ fontSize: 14 }}>
                    <AmplifyLink
                      target='_blank'
                      href={ it }
                    >
                      { general.ellipsisInMiddle(it, 110) }
                    </AmplifyLink>
                  </div>
                ))
              }
            </div> : <></>
        }
      </div> */}
    </div>
  </>
}
