import { Button, Flex, TextField } from '@aws-amplify/ui-react';
import './ResourceTags.scss';
import { BiX } from 'react-icons/bi';
import general from '../utils/general';
import { toast } from 'react-toastify';
import { useState } from 'react';

export default function ResourceTags({
  tags,
  tagPool,
  isEditable = true,
  resource,
  resourceId,
  onDeleteTag,
  onSaveTag,
  onTagClicked,
}: {
  tags: string[],
  tagPool: string[],
  isEditable: Boolean,
  resource: string,
  resourceId: string,
  onDeleteTag: (tag: string) => void,
  onSaveTag: (tag: string) => void,
  onTagClicked: (tag: string) => void,
}) {

  const [isAddingTag, setIsAddingTag] = useState(false);
  const [isSavingTag, setIsSavingTag] = useState(false);
  const [isDeleting, setIsDeleting] = useState<any>({});
  const [newTag, setNewTag] = useState('');

  const saveNewTag = async (tag: string) => {
    try {
      setIsSavingTag(true)
      await general.saveTag({ resource, resourceId, tag })
      setNewTag('')
      onSaveTag(tag)
    } catch (err) {
      console.log(err)
      toast.warn('Adding new tag failed, please try again')
    } finally {
      setIsSavingTag(false)
    }
  }

  return <>
    <Flex wrap='wrap' alignItems='baseline' gap={3}>
      <div className='add-tag' style={{ marginRight: 5 }}>
        <div className='tiny-button nonclickable-row' onClick={() => setIsAddingTag(!isAddingTag)}>
          Add Tag
        </div>
        <div className='adding-tag-dialog nonclickable-row'>
          <div className="card nonclickable-row">
            <Flex alignItems='flex-start' gap={3}>
              <TextField
                size="small"
                placeholder="your tag"
                style={{ padding: '3px 5px' }}
                gap={3}
                minLength={1}
                maxLength={30}
                errorMessage="There is an error"
                value={newTag || ''}
                onKeyUp={(key) => {
                  if (key.key === 'Enter') {
                    saveNewTag(newTag)
                  }
                }}
                label={<></>}
                onChange={event => {
                  setNewTag(event.target.value.replace(/[^A-Za-z0-9- ]/g, '').toLowerCase())
                }}
              />
              <Button
                className='tiny'
                style={{ transform: 'translateY(3px)' }}
                disabled={!newTag || isSavingTag || tags?.length >= 15}
                data-tooltip-id="main-tooltip"
                data-tooltip-hidden={tags?.length < 15}
                data-tooltip-content="You can add at most 15 contacts per contact"
                data-tooltip-offset={25}
                onClick={() => saveNewTag(newTag)}
              >
                { isSavingTag ? 'Adding...' : 'Add' }
              </Button>
            </Flex>
            {
              tagPool?.length ?
                <Flex gap={3} className="available-tags nonclickable-row">
                  {
                    tagPool.map(it => (
                      <div
                        key={it}
                        className="tag tag-neutral is-hoverable nonclickable-row"
                        onClick={() => saveNewTag(it)}
                      >
                        { it }
                      </div>
                    ))
                  }
                </Flex> : <></>
            }
          </div>
        </div>
      </div>
      {
        (tags || []).map((tag: any) => (
          <div key={tag} className='tag-container' style={{ opacity: isDeleting[tag] ? 0.4 : 1 }}>
            <div
              className="tag tag-neutral is-hoverable nonclickable-row"
              onClick={() => {
                onTagClicked(tag)
              }}
            >
              { tag }
            </div>
            {
              (isEditable && !isDeleting[tag]) ?
                <div className="delete nonclickable-row" onClick={async () => {
                  try {
                    setIsDeleting({
                      ...isDeleting,
                      [tag]: true
                    })
                    await general.deleteTag({ resource, resourceId, tag })
                    onDeleteTag(tag)
                  } catch (err) {
                    console.log(err)
                    toast.warn('Failed to delete tag, please try again', { autoClose: false })
                  } finally {
                    setIsDeleting({
                      ...isDeleting,
                      tag: false
                    })
                  }
                }}>
                  <BiX fontSize={14} />
                </div> : <></>
            }
          </div>
        ))
      }
    </Flex>
  </>
}
