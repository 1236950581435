import { Button, Flex, Pagination } from '@aws-amplify/ui-react';
import { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { GlobalContext, GlobalContextProps } from '../global-context';
import { InstitutionsSearchParams } from '../utils/InstitutionsSearchParams';
import general from '../utils/general';
import QueryEditor from '../custom-components/QueryEditor';
import InstitutionsList from '../custom-components/InstitutionsList';
import { TeamDao } from '../dao/team';

const PAGE_SIZE = 25

export function Institutions({
  type
}: {
  type: 'CHILD' | 'PARENT'
}) {

  const globalContext = useContext(GlobalContext);
  const institution = type === 'CHILD' ? globalContext.environment.entities.childInstitution : globalContext.environment.entities.parentInstitution;

  general.setTitle(document, institution.namePlural, globalContext);

  const location = useLocation();
  const navigate = useNavigate();
  
  const institutionsListRef = useRef();

  const [institutionsSearchResponse, setInstitutionsSearchResponse] = useState<any>(null);
  const [isExportingToCsv, setIsExportingToCsv] = useState(false);
  const [isLoadingInstitutions, setIsLoadingInstitutions] = useState(false);
  const [currentSearchParams, setCurrentSearchParams] = useState<InstitutionsSearchParams>();
  const [newSearchParams, setNewSearchParams] = useState<InstitutionsSearchParams>();

  async function loadInstitutions() {
    try {
      setIsLoadingInstitutions(true)
      const body = { pageSize: PAGE_SIZE, entity: institution.type }
      Object.assign(body, currentSearchParams?.toBody())
      const bodyJson = await TeamDao.dbSearch(body)
      setInstitutionsSearchResponse(bodyJson);
    } catch (err) {
      toast.warn(`Something went wrong loading institutions${(err as any)?.message ? `. Reason: ${(err as any)?.message}` : ''}`, { autoClose: 10000 })
    } finally {
      setIsLoadingInstitutions(false)
    }
  }

  function handlePageChange(newPageNumber: number | undefined) {
    if (!newPageNumber) return
    navigate(`?${newSearchParams?.serialize(`${newPageNumber}`)}`);
  }

  function handleOnSearch() {
    navigate(`?${newSearchParams?.serialize('1')}`);
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const params = {
      q: general.getQueryParamValueOrNull(queryParams, 'q'),
      sortBy: general.getQueryParamValueOrNull(queryParams, 'sortBy'),
      sortDir: general.getQueryParamValueOrNull(queryParams, 'sortDir'),
      page: general.getQueryParamValueOrNull(queryParams, 'page'),
    }
    setCurrentSearchParams(new InstitutionsSearchParams(params))
    setNewSearchParams(new InstitutionsSearchParams(params))
  }, [location.search]);

  useEffect(() => {
    if (!currentSearchParams) return
    loadInstitutions()
  }, [currentSearchParams]);

  function onSort(sortBy: string, sortDir: string) {
    const sortedSearchParams = new InstitutionsSearchParams({
      ...newSearchParams?.raw(),
      ...{ sortBy, sortDir }
    })
    navigate(`?${sortedSearchParams?.serialize('1')}`);
  }

  async function onExportToCsv(ids: any[] | null = null) {
    try {
      setIsExportingToCsv(true)
      const body: any = { pageSize: PAGE_SIZE, entity: institution.type, action: 'export-csv' }
      if (Array.isArray(ids)) body.ids = ids
      Object.assign(body, currentSearchParams?.toBody())
      const bodyJson: any = await TeamDao.dbSearch(body)
      await general.downloadUrl(bodyJson?.data?.downloadUrl, bodyJson?.data?.fileName)
    } catch (error: any) {
      toast.warn(general.getErrorMessage('Something went wrong exporting to a CSV', error))
      console.error(error)
    } finally {
      setIsExportingToCsv(false)
    }
  }

  return (
    <GlobalContext.Consumer>
      {
        (globalContextProps: GlobalContextProps) => <>
          <div className="content content-1000">
            <div className="main-card">
              <div style={{ padding: 15 }}>
                {
                  currentSearchParams
                    ? <QueryEditor
                        initialQuery={currentSearchParams?.q.serialize()}
                        teamTags={[]}
                        type='standard'
                        supportedFields={institution.queryEditorSupportedFields}
                        onChange={(query: string | null) => {
                          setNewSearchParams(prevState => new InstitutionsSearchParams({
                            ...prevState?.raw(),
                            ...{ q: query }
                          }))
                        }}
                      />
                    : <></>
                }
                <Flex justifyContent='flex-end' marginTop={30} gap={5}>
                  <Button
                    variation='primary'
                    size='small'
                    isDisabled={currentSearchParams?.serialized === newSearchParams?.serialized}
                    isLoading={isLoadingInstitutions}
                    onClick={handleOnSearch}
                    loadingText="Searching"
                  >Search</Button>
                </Flex>
              </div>
            </div>
          </div>

          <div className="content content-1000">
            <InstitutionsList
              institutionsSearchResponse={institutionsSearchResponse}
              isLoading={isLoadingInstitutions}
              isExportingToCsv={isExportingToCsv}
              onSort={onSort}
              onExportToCsv={onExportToCsv}
              ref={institutionsListRef}
              sortOptions={institution.sortOptions}
              entity={institution.type}
            />
            {
              !isLoadingInstitutions &&
                <Flex
                  marginTop={30}
                  marginBottom={50}
                  justifyContent='center'
                >
                  <Pagination
                    currentPage={parseInt(currentSearchParams?.page.value)}
                    totalPages={Math.ceil(institutionsSearchResponse?.data?.hits?.total?.value / PAGE_SIZE)}
                    siblingCount={1}
                    onChange={handlePageChange}
                  />
                </Flex>
            }
          </div>
        </>
      }
    </GlobalContext.Consumer>
  )
}
