import { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoadingBars from '../custom-components/LoadingBars';
import { GlobalContext } from '../global-context';
import general from '../utils/general';
import ContactList from '../custom-components/ContactList';
import InstitutionTopCard from '../custom-components/InstitutionTopCard';
import { TeamDao } from '../dao/team';
import InstitutionsList from '../custom-components/InstitutionsList';

const PAGE_SIZE = 1000

export function ParentInstitution(props: any) {

  const globalContext = useContext(GlobalContext);
  const childInstitution = globalContext.environment.entities.childInstitution;
  const parentInstitution = globalContext.environment.entities.parentInstitution;

  const { id } = useParams()
  const [institutionId] = useState(id);

  const [responseParent, setResponseParent] = useState<any>(null);
  const [responseContacts, setResponseContacts] = useState<any>(null);
  const [responseChildInstitutions, setResponseChildInstitutions] = useState<any>(null);

  const [isLoadingParent, setIsLoadingParent] = useState(false);
  const [isLoadingChildren, setIsLoadingChildren] = useState(false);
  const [isLoadingContacts, setIsLoadingContacts] = useState(false);

  const [isExportingContactsToCsv, setIsExportingContactsToCsv] = useState(false);
  const [isExportingChildInstitutionsToCsv, setIsExportingChildInstitutionsToCsv] = useState(false);

  const institutionsListRef = useRef();

  async function loadParentInstitution() {
    try {
      setIsLoadingParent(true)
      const body = { entity: parentInstitution.type, id: institutionId }
      const bodyJson = await TeamDao.dbGet(body)
      setResponseParent(bodyJson);
      general.setTitle(document, bodyJson?.data?._source?.name, globalContext);
    } catch (err) {
      toast.warn(general.getErrorMessage('Something went wrong loading institutions', err))
      console.error(err)
    } finally {
      setIsLoadingParent(false)
    }
  }

  async function loadContacts() {
    try {
      setIsLoadingContacts(true)
      const body = {
        pageSize: 200,
        page: 1,
        entity: 'contacts',
        q: general.getQ({ rules: [{ field: 'institutionId', value: institutionId, operator: '=' }] }),
        sortBy: 'titleCategory',
        sortDir: 'asc',
      }
      const bodyJson = await TeamDao.dbSearch(body)
      setResponseContacts(bodyJson);
    } catch (error) {
      toast.warn(general.getErrorMessage('Something went wrong loading contacts', error))
      console.error(error)
    } finally {
      setIsLoadingContacts(false)
    }
  }

  async function loadChildInstitutions() {
    try {
      setIsLoadingChildren(true)
      const body = {
        pageSize: PAGE_SIZE,
        page: 1,
        entity: childInstitution.type,
        q: general.getQ({ rules: [{ field: 'idParentInstitution', value: institutionId, operator: '=' }] })
      }
      const bodyJson = await TeamDao.dbSearch(body)
      setResponseChildInstitutions(bodyJson);
    } catch (error) {
      toast.warn(general.getErrorMessage('Something went wrong loading child institutions', error))
      console.error(error)
    } finally {
      setIsLoadingChildren(false)
    }
  }

  async function onExportContactsToCsv(ids?: string[]) {
    try {
      setIsExportingContactsToCsv(true)
      const data: any = {
        pageSize: PAGE_SIZE,
        page: 1,
        entity: 'contacts',
        q: general.getQ({ rules: [{ field: 'institutionId', value: institutionId, operator: '=' }] }),
        sortBy: 'titleCategory',
        sortDir: 'asc',
        action: 'export-csv',
      }
      if (Array.isArray(ids)) data.ids = ids
      const bodyJson = await TeamDao.dbSearch(data)
      await general.downloadUrl(bodyJson?.data?.downloadUrl, bodyJson?.data?.fileName)
    } catch (error: any) {
      toast.warn(general.getErrorMessage('Something went wrong exporting to a CSV', error))
      console.error(error)
    } finally {
      setIsExportingContactsToCsv(false)
    }
  }

  async function onExportChildInstitutionsToCsv(ids?: string[]) {
    try {
      setIsExportingChildInstitutionsToCsv(true)
      const data: any = {
        pageSize: PAGE_SIZE,
        page: 1,
        entity: childInstitution.type,
        action: 'export-csv',
        q: general.getQ({ rules: [{ field: 'idParentInstitution', value: institutionId, operator: '=' }] })
      }
      if (Array.isArray(ids)) data.ids = ids
      const bodyJson = await TeamDao.dbSearch(data)
      await general.downloadUrl(bodyJson?.data?.downloadUrl, bodyJson?.data?.fileName)
    } catch (error) {
      toast.warn(general.getErrorMessage('Something went wrong exporting child institutions to a CSV', error))
      console.error(error)
    } finally {
      setIsExportingChildInstitutionsToCsv(false)
    }
  }

  function onSaveTag(row: any, savedTag: string) {
    if (!Array.isArray(row.tags)) row.tags = []
    row.tags.push(savedTag)
  }

  function onDeleteTag(row: any, deletedTag: string) {
    const index = row.tags.findIndex((it: string) => it === deletedTag)
    row.tags.splice(index, 1)
  }

  useEffect(() => {
    loadParentInstitution();
    loadChildInstitutions();
    loadContacts();
  }, [props.id]);

  return (
    <GlobalContext.Consumer>
      {(globalContextProps) =>
        <>
          <div className="content content-1000">
            {
              isLoadingParent || !responseParent ?
                <LoadingBars style={{ margin: '20px 0' }} numberOfRows={1} /> :
                <h1 style={{ margin: 0 }}>{responseParent?.data?._source?.name}</h1>
            }
          </div>
          <div className="content content-1000">
          {
            isLoadingParent || !responseParent ?
              <LoadingBars numberOfRows={5} /> :
              <InstitutionTopCard institution={responseParent.data._source} />
          }
          </div>
          <div className="content content-1000">
            <InstitutionsList
              institutionsSearchResponse={responseChildInstitutions}
              isLoading={isLoadingChildren}
              isExportingToCsv={isExportingChildInstitutionsToCsv}
              showSortButton={false}
              onSort={() => {}}
              onExportToCsv={(ids?: string[]) => onExportChildInstitutionsToCsv(ids)}
              ref={institutionsListRef}
              sortOptions={[]}
              entity={childInstitution.type}
            />
          </div>
          <div className="content content-1000">
            <ContactList
              contactsSearchResponse={responseContacts}
              isLoadingContacts={isLoadingContacts}
              isExportingToCsv={isExportingContactsToCsv}
              onSort={() => {}}
              onExportToCsv={(ids?: string[]) => onExportContactsToCsv(ids)}
              onDeleteTag={onDeleteTag}
              onSaveTag={onSaveTag}
              ref={null}
              showSortButton={false}
              showTags={false}
              showFavorite={false}
              showEdit={true}
            />
          </div>
        </>
      }
    </GlobalContext.Consumer>
  );
}

export default ParentInstitution;