import { post } from 'aws-amplify/api';
import { GlobalContext, GlobalContextProps } from '../global-context';
import general from '../utils/general';
import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import FeedAnnouncementItem from '../custom-components/FeedItems/FeedAnnouncementItem';
import FeedRescanSuperintendentsItem from '../custom-components/FeedItems/FeedRescanSuperintendentsItem';
import { Button } from '@aws-amplify/ui-react';
import LoadingBars from '../custom-components/LoadingBars';

const allowedTypes: any = {
  ANNOUNCEMENT: {
    humanReadable: 'announcement',
  },
  RESCAN_SUPERINTENDENTS: {
    humanReadable: 'rescan',
  },
}

export function Home(props: any) {

  const globalContext = useContext(GlobalContext);
  general.setTitle(document, 'Feed', globalContext);

  const location = useLocation();

  const [isLoadingEvents, setIsLoadingEvents] = useState(true);
  const [eventsResponse, setEventsResponse] = useState<any>(null);

  async function getEvents() {
    try {
      setIsLoadingEvents(true)
      const headers = await general.getAuthorizationHeaders()
      const body: any = {
        action: 'LIST_EVENTS',
        data: { page: 1 }
      }
      const listOperation = post({
        apiName: 'apiMain',
        path: '/data',
        options: { headers, body }
      });
      const res = await listOperation.response
      const data = await res.body.json() as any
      setEventsResponse(data?.data)
    } catch (err) {
      toast.error(`Something went wrong while fetching events`)
    } finally {
      setIsLoadingEvents(false)
    }
  }

  useEffect(() => {
    getEvents()
  }, [location.search]);

  return <GlobalContext.Consumer>
    {
      (globalContextProps: GlobalContextProps) => <>
        <div className="content content-800" style={{ padding: 0 }}>
          <div className="feed">
            <div className='feed-controls'>
              <Button
                size='small'
                className='tiny'
                onClick={event => getEvents()}
              >
                Refresh
              </Button>
            </div>
            {
              isLoadingEvents
                ? <div style={{ padding: 25 }}><LoadingBars numberOfRows={10} /></div>
                : eventsResponse?.events
                    ?.filter((event: any) => allowedTypes[event.type])
                    ?.map((event: any) => <div key={event?.id} className={`post post-type-${event?.type}`}>
                      <div className='post-title'>
                        <div className='post-type'>@{ allowedTypes[event.type]?.humanReadable }</div>
                        <div className='post-time'>{ general.daysAgo(event.createdAt) }</div>
                      </div>
                      { event.type === 'ANNOUNCEMENT' && <FeedAnnouncementItem event={event} /> }
                      { event.type === 'RESCAN_SUPERINTENDENTS' && <FeedRescanSuperintendentsItem event={event} /> }
                    </div>)
            }
          </div>
        </div>
      </>
    }
  </GlobalContext.Consumer>
}
