import { post } from 'aws-amplify/api';
import 'rc-slider/assets/index.css';
import { useContext, useEffect, useState } from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoadingBars from '../custom-components/LoadingBars';
import { GlobalContext } from '../global-context';
import general from '../utils/general';
import ContactList from '../custom-components/ContactList';
import InstitutionTopCard from '../custom-components/InstitutionTopCard';

const PAGE_SIZE = 2000

export function ChildInstitution(props: any) {

  const { id } = useParams()
  const [uid] = useState(id);
  const [response, setResponse] = useState<any>(null);
  const [contacts, setContacts] = useState<any>(null);
  const [isExportingToCsv, setIsExportingToCsv] = useState(false);
  const [isLoadingContacts, setIsLoadingContacts] = useState(false);
  const globalContext = useContext(GlobalContext);

  async function loadSchool() {
    setIsLoadingContacts(true)
    const headers = await general.getAuthorizationHeaders()
    const body = { action: 'DB_GET', data: { entity: 'schools', id: uid as string } };
    try {
      const postOperation = post({
        apiName: 'apiMain',
        path: '/data',
        options: {
          headers,
          body
        }
      });
      const response = await postOperation.response
      const bodyJson = await response.body.json() as any
      setResponse(bodyJson);
      loadContacts(bodyJson.data._id)
      general.setTitle(document, bodyJson.data._source.name, globalContext);
    } catch (err) {
      toast.warn('Failed to load school, please try again', { autoClose: false })
    }
  }

  async function loadContacts(institutionId: string) {
    const headers = await general.getAuthorizationHeaders()
    const body = {
      action: 'DB_SEARCH',
      data: {
        pageSize: 200,
        page: 1,
        entity: 'contacts',
        q: general.getQ({ rules: [{ field: 'institutionId', value: institutionId, operator: '=' }] }),
        sortBy: 'titleCategory',
        sortDir: 'asc',
      }
    }
    try {
      const postOperation = post({
        apiName: 'apiMain',
        path: '/data',
        options: {
          headers,
          body
        }
      });
      const response = await postOperation.response
      const bodyJson = await response.body.json() as any
      setContacts(bodyJson);
    } catch (err) {
      toast.warn('Failed to load contacts, please try again', { autoClose: false })
    } finally {
      setIsLoadingContacts(false)
    }
  }

  function onSaveTag(row: any, savedTag: string) {
    if (!Array.isArray(row.tags)) row.tags = []
    row.tags.push(savedTag)
  }

  function onDeleteTag(row: any, deletedTag: string) {
    const index = row.tags.findIndex((it: string) => it === deletedTag)
    row.tags.splice(index, 1)
  }

  async function onExportToCsv(institutionId: string, ids?: string[]) {
    setIsExportingToCsv(true)
    try {
      const headers = await general.getAuthorizationHeaders();
      const body: any = {
        action: 'DB_SEARCH',
        data: {
          pageSize: PAGE_SIZE,
          page: 1,
          entity: 'contacts',
          q: general.getQ({ rules: [{ field: 'institutionId', value: institutionId, operator: '=' }] }),
          sortBy: 'titleCategory',
          sortDir: 'asc',
          action: 'export-csv',
        }
      }

      if (Array.isArray(ids)) body.data.ids = ids

      const postOperation = post({
        apiName: 'apiMain',
        path: '/data',
        options: {
          headers,
          body
        }
      });
      const response = await postOperation.response
      const bodyJson = await response.body.json() as any

      await general.downloadUrl(bodyJson?.data?.downloadUrl, bodyJson?.data?.fileName)
    } catch (error: any) {
      toast.warn(general.getErrorMessage('Something went wrong exporting to a CSV', error))
      console.error(error)
    } finally {
      setIsExportingToCsv(false)
    }
  }

  useEffect(() => {
    loadSchool();
  }, [props.id]);

  return (
    <GlobalContext.Consumer>
      {(globalContextProps) => (
        <>
          <div className="content content-1000">
            {
              !response ?
                <LoadingBars style={{ margin: '20px 0' }} numberOfRows={1} /> :
                  response.data._source.access === 'PRIVATE' ?
                    <h1 style={{ margin: 0 }}>{response.data._source.name}</h1> :
                    response.data._source.dsName ?
                      <h1 style={{ margin: 0 }}><Link to={`/directory/${globalContext.environment.entities.parentInstitution.urlSegment}/${response?.data?._source?.dsId || ''}`}>{response.data._source.dsName}</Link> <FaArrowRight color='var(--amplify-colors-neutral-80)' size={16} style={{ marginRight: 5, marginLeft: 5 }} /> {response.data._source.name}</h1>
                      : <h1 style={{ margin: 0 }}>{ response.data._source.name }</h1>
            }
          </div>
          <div className="content content-1000">
          {
            !response ?
              <LoadingBars numberOfRows={5} /> :
              <InstitutionTopCard institution={response.data._source} />
          }
          </div>
          <div className="content content-1000">
            <ContactList
              contactsSearchResponse={contacts}
              isLoadingContacts={isLoadingContacts}
              isExportingToCsv={isExportingToCsv}
              onSort={() => {}}
              onExportToCsv={(ids?: string[]) => onExportToCsv(uid as string, ids)}
              onDeleteTag={onDeleteTag}
              onSaveTag={onSaveTag}
              ref={null}
              showSortButton={false}
              showTags={false}
              showFavorite={false}
              showEdit={true}
            />
          </div>
        </>
      )}
    </GlobalContext.Consumer>
  );
}

export default ChildInstitution;