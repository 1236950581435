import { Link as AmplifyLink, Button, CheckboxField, Flex, Image, Input, Loader, Menu, MenuButton } from '@aws-amplify/ui-react';
import { Link } from 'react-router-dom';
import LoadingBars from "./LoadingBars";
import { BiChevronDown, BiChevronUp, BiSolidCheckCircle, BiSolidPencil } from "react-icons/bi";
import EmailAddress from "./EmailAddress";
import { forwardRef, useContext, useEffect, useImperativeHandle, useState } from 'react';
import general from '../utils/general';
import { toast } from 'react-toastify';
import { post } from 'aws-amplify/api';
import NA from './NA';
import TitleCategoryTag from './TitleCategoryTag';
import InstitutionTypeTag from './InstitutionTypeTag';
import ResourceTags from './ResourceTags';
import ReactModal from 'react-modal';
import { GlobalContext } from '../global-context';

export interface Box {
  x: number
  y: number
  width: number
  height: number
}

export interface BoundingBox {
  meta: {
    value: string
    type: string
  }
  boxes: Box[]
}

export interface ScreenshotSize {
  width: number
  height: number
}

export interface UrlVersion {
  screenshot: string
  screenshot_size: ScreenshotSize
  boxes: BoundingBox[]
}

const ContactList = ({
  contactsSearchResponse,
  isLoadingContacts,
  isExportingToCsv,
  onSort,
  onExportToCsv,
  onDeleteTag,
  onSaveTag,
  showSortButton = true,
  showExportButton = true,
  showTags = true,
  showFavorite = true,
  showEdit = true,
}: {
  contactsSearchResponse: any,
  isLoadingContacts: boolean,
  isExportingToCsv: boolean,
  onSort: (sortBy: string, sortDir: string) => void,
  onExportToCsv: (ids?: any[]) => void,
  onDeleteTag: (row: any, tag: string) => void,
  onSaveTag: (row: any, tag: string) => void,
  showSortButton?: boolean,
  showExportButton?: boolean,
  showTags?: boolean,
  showFavorite?: boolean,
  showEdit?: boolean,
}, ref: any) => {
  const [selectedContacts, setSelectedContacts] = useState<{[key: string]: any}>({});
  const [selectedContactsCount, setSelectedContactsCount] = useState(0);
  const [selectedScreenshot, setSelectedScreenshot] = useState<UrlVersion|undefined>(undefined);
  const [isSavingSelectedContactForOverride, setIsSavingSelectedContactForOverride] = useState<boolean>(false);
  const [selectedContactForOverride, setSelectedContactForOverride] = useState<any|null>(null);
  const [expanedRows, setExpandedRows] = useState<{
    sources: any,
    institutions: any,
    fulltext: any,
    tags: any,
  }>({ sources: {}, institutions: {}, fulltext: {}, tags: {} });
  const [formOverrideData, setFormOverrideData] = useState<{
    name: string,
    title: string,
    email: string,
    phone: string,
  }>({
    name: '',
    title: '',
    email: '',
    phone: ''
  });

  const globalContext = useContext(GlobalContext);

  async function toggleAll(event: any) {
    const contactIds: any = {}
    for (const item of contactsSearchResponse?.data?.hits?.hits) contactIds[item._id] = item;
    if (event.target.checked) {
      // If checked, add all contacts to selectedContacts
      setSelectedContacts(prevState => ({
        ...prevState,
        ...contactIds
      }));
    } else {
      // If unchecked, remove all contacts from selectedContacts
      const updatedContacts = Object.assign({}, selectedContacts);
      for (const key in contactIds) {
        delete updatedContacts[key];
      }
      setSelectedContacts(prevState => updatedContacts);
    }
  }

  function handleCheckboxChange(row: any, event: any) {
    const itemId = row._id;
    const isChecked = event.target.checked;
    setSelectedContacts(prevState => ({
      ...prevState,
      [itemId]: isChecked ? row : null
    }));
  }

  async function saveOverride() {
    try {
      setIsSavingSelectedContactForOverride(true)
      const headers = await general.getAuthorizationHeaders()
      const body: any = {
        action: 'CREATE_OVERRIDE',
        data: {
          entity: 'CONTACT',
          id: selectedContactForOverride._id,
          value: formOverrideData
        }
      }
      const operation = post({
        apiName: 'apiMain',
        path: '/data',
        options: { headers, body }
      });
      const response = await operation.response
      const bodyJson = await response.body.json() as any
      const updatedContact = bodyJson?.data

      const updatedContactSearchResponse = contactsSearchResponse?.data?.hits?.hits
      if (Array.isArray(updatedContactSearchResponse)) {
        const contact = updatedContactSearchResponse.find((it: any) => it._id === updatedContact.id)
        Object.assign(contact._source, updatedContact?.value || {})
        // TODO
        // setContactsSearchResponse(contactsSearchResponse)
      }

      toast.success('Successfully updated the contact', { autoClose: 1500, isLoading: false })
      setSelectedContactForOverride(null)
    } catch(e) {
      toast.warn(general.getErrorMessage('Something went wrong updating the contact', e))
      console.error(e)
    } finally {
      setIsSavingSelectedContactForOverride(false)
    }
  }

  function getBoundingBoxPosition(screenshot_size: ScreenshotSize, box: Box) {
    try {
      const ratio = 900 / screenshot_size.width;
      const width = box.width * ratio;
      const height = box.height * ratio;
      const top = box.y * ratio;
      const left = box.x * ratio;
      const averageLeft = left + width / 2;
      const averageTop = top + height / 2;
      return {
        top: `${averageTop}px`,
        left: `${averageLeft}px`,
      }
    } catch {
      return {
        top: '0px',
        left: '0px',
      }
    }
  }

  useEffect(() => {
    setSelectedContactsCount(general.getTrueKeys(selectedContacts).length);
  }, [selectedContacts])

  useImperativeHandle(ref, () => ({
    clearSelectedContacts() {
      setSelectedContacts({})
    }
  }));

  return <>
    {
      isLoadingContacts ?
        <LoadingBars numberOfRows={15} /> :
        !contactsSearchResponse?.data?.hits?.hits?.length ?
        <div style={{ marginTop: 20, color: 'var(--amplify-colors-neutral-80)', textAlign: 'center' }}></div> :
        <>
          <div className="main-card">
            <div className='results-row results-header'>
              <div className="results-row-left-side" style={{ margin: '5px 4px 4px 4px' }}>
                <CheckboxField
                  label=""
                  name="subscribe"
                  value="yes"
                  size="large"
                  // checked={selectedContacts[row._id] || ''}
                  onChange={toggleAll}
                />
              </div>
              <div className="results-row-content">
                <Flex
                  alignItems='center'
                  justifyContent='space-between'
                >
                  <div className='desc-3'>
                    Found { general.addCommasToNumber(contactsSearchResponse?.data?.hits?.total?.value) }{contactsSearchResponse?.data?.hits?.total?.value === 10000 ? '+' : ''} {contactsSearchResponse?.data?.hits?.total?.value === 1 ? 'match' : 'matches'}
                    {
                      selectedContactsCount > 0 && <span>, { selectedContactsCount } contact{ selectedContactsCount === 1 ? '' : 's' } selected</span>
                    }
                  </div>
                  <Flex direction='row' gap={5}>
                    <div>
                      { isExportingToCsv && <Loader style={{ marginRight: 10, transform: 'translateY(7px)' }} /> }
                    </div>
                    {
                      showSortButton &&
                        <Menu
                          size='small'
                          alignItems='start'
                          trigger={
                            <MenuButton
                              size='small'
                              className='tiny'
                            >
                              Sort <BiChevronDown style={{ marginLeft: 5, marginRight: -3, fontSize: 15 }} />
                            </MenuButton>
                          }
                        >
                          <Button key='nameasc' className='menu-item' onClick={onSort.bind(null, 'name', 'asc')}>By name, A to Z</Button>
                          <Button key='namedesc' className='menu-item' onClick={onSort.bind(null, 'name', 'desc')}>By name, Z to A</Button>
                          <Button key='emailasc' className='menu-item' onClick={onSort.bind(null, 'email', 'asc')}>By email, A to Z</Button>
                          <Button key='emaildesc' className='menu-item' onClick={onSort.bind(null, 'email', 'desc')}>By email, Z to A</Button>
                          <Button key='phoneasc' className='menu-item' onClick={onSort.bind(null, 'phone', 'asc')}>By phone, 0 to 9</Button>
                          <Button key='phonedesc' className='menu-item' onClick={onSort.bind(null, 'phone', 'desc')}>By phone, 9 to 0</Button>
                          <Button key='stateasc' className='menu-item' onClick={onSort.bind(null, 'state', 'asc')}>By state, A to Z</Button>
                          <Button key='statedesc' className='menu-item' onClick={onSort.bind(null, 'state', 'desc')}>By state, Z to A</Button>
                        </Menu>
                    }
                    {
                      showExportButton &&
                        <Menu
                          size='small'
                          alignItems='start'
                          trigger={
                            <MenuButton
                              size='small'
                              className='tiny'
                            >
                              Export <BiChevronDown style={{ marginLeft: 5, marginRight: -3, fontSize: 15 }} />
                            </MenuButton>
                          }
                        >
                          <Button
                            key='exportselected'
                            disabled={selectedContactsCount === 0 || isExportingToCsv}
                            className='menu-item'
                            onClick={() => {
                              onExportToCsv(Object.keys(selectedContacts))
                            }}
                          >
                            Export Selected to CSV ({ selectedContactsCount })
                          </Button>
                          <Button
                            key='exportall'
                            disabled={isExportingToCsv}
                            className='menu-item'
                            onClick={() => {
                              onExportToCsv()
                            }}
                          >
                            Export All to CSV
                          </Button>
                        </Menu>
                    }
                    
                    {/* <Button
                      disabled={selectedContactsCount === 0}
                      size='small'
                      onClick={() => {
                        setIsHubspotSyncModalOpened(true)
                      }}
                    >
                      Sync to Hubspot ({ selectedContactsCount })
                    </Button> */}
                  </Flex>
                </Flex>
              </div>
            </div>
            <div className='results-table'>
              {
                contactsSearchResponse?.data?.hits?.hits?.map((row: any) => (
                  <div
                    key={row._id}
                    className="results-row"
                    // onClick={(event) => {
                    //   if ((event.target as HTMLElement).tagName === 'DIV' && !(event.target as HTMLElement).classList.contains('nonclickable-row')) {
                    //     setSelectedContact(row)
                    //     setIsContactDetailDrawerOpened(true)
                    //   }
                    // }}
                  >
                    <div className="results-row-left-side">
                      <div style={{ margin: '1px 4px' }}>
                        <CheckboxField
                          label=""
                          name="subscribe"
                          value="yes"
                          id={row._id}
                          size="large"
                          checked={selectedContacts[row._id] || ''}
                          onChange={(event) => {
                            handleCheckboxChange(row, event)
                            event.stopPropagation();
                          }}
                        />
                      </div>
                    </div>
                    <div className='results-row-content'>
                      <div className="results-row-main-row">
                        <div style={{ flex: 1.8 }}>
                          <div className='results-row-main-line'>
                            <Link className='main-table-link' to={'/directory/contacts/' + row._id}>
                              { row._source.name || <NA /> }
                            </Link>
                            <span className='main-table-title' title={row._source.title}>
                              { row._source.title || <NA /> }
                            </span>
                            <TitleCategoryTag titleCategory={row._source.titleCategory} />
                          </div>
                          {
                            row._source?.bios?.length > 0 &&
                              <div className='desc-9'>{ row._source?.bios[0]?.bio }</div>
                          }
                          <div style={{ display: 'flex', marginBottom: -8 }}>
                            {
                              row._source?.fulltextMatchedUrls?.length ?
                                <div style={{ marginRight: 10 }}>
                                  <span
                                    className='tiny-button'
                                    onClick={() => {
                                      const isExpanded = expanedRows.fulltext[row._id]
                                      setExpandedRows({
                                        ...expanedRows,
                                        fulltext: {
                                          ...expanedRows.fulltext,
                                          [row._id]: !isExpanded
                                        },
                                        sources: {
                                          ...expanedRows.sources,
                                          [row._id]: false
                                        },
                                        institutions: {
                                          ...expanedRows.institutions,
                                          [row._id]: false
                                        }
                                      })
                                    }}
                                  >
                                    { row._source?.fulltextMatchedUrls?.length } URL{ row._source?.fulltextMatchedUrls?.length === 1 ? '' : 's' } matching fulltext search { expanedRows.fulltext[row._id] ? <BiChevronUp /> : <BiChevronDown /> }
                                  </span>
                                </div> : <></>
                            }
                            {
                              row._source?.foundOnUrls?.length ?
                                <div style={{ marginRight: 10 }}>
                                  <span
                                    className='tiny-button'
                                    onClick={() => {
                                      const isExpanded = expanedRows.sources[row._id]
                                      setExpandedRows({
                                        ...expanedRows,
                                        sources: {
                                          ...expanedRows.sources,
                                          [row._id]: !isExpanded
                                        },
                                        institutions: {
                                          ...expanedRows.institutions,
                                          [row._id]: false
                                        },
                                        fulltext: {
                                          ...expanedRows.fulltext,
                                          [row._id]: false
                                        }
                                      })
                                    }}
                                  >
                                    { row._source?.foundOnUrls?.length } Source{ row._source?.foundOnUrls?.length === 1 ? '' : 's' } { expanedRows.sources[row._id] ? <BiChevronUp /> : <BiChevronDown /> }
                                  </span>
                                </div> : <></>
                            }
                            {
                              row._source?.belongsTo?.length ?
                                <div style={{ marginRight: 5 }}>
                                  <span
                                    className='tiny-button'
                                    onClick={() => {
                                      const isExpanded = expanedRows.institutions[row._id]
                                      setExpandedRows({
                                        ...expanedRows,
                                        sources: {
                                          ...expanedRows.sources,
                                          [row._id]: false
                                        },
                                        institutions: {
                                          ...expanedRows.institutions,
                                          [row._id]: !isExpanded
                                        },
                                        fulltext: {
                                          ...expanedRows.fulltext,
                                          [row._id]: false
                                        }
                                      })
                                    }}
                                  >
                                    { row._source['belongsTo']?.length } { general.getStatesFromBelongsTo(row._source['belongsTo']) } institution{ row._source['belongsTo']?.length === 1 ? '' : 's' } { expanedRows.institutions[row._id] ? <BiChevronUp /> : <BiChevronDown /> }
                                  </span>
                                </div> : <></>
                            }
                          </div>
                        </div>
                        <div style={{ flex: 1 }}>
                          <div>
                            <EmailAddress email={row._source['email']} emailDomainCount={row._source['emailDomainCount']} emailVerificationStatus={row._source['emailVerificationStatus']} emailLastVerifiedAt={row._source['emailLastVerifiedAt']} />
                          </div>
                          <div style={{ marginTop: 5 }}>
                            { row._source['phone'] }
                          </div>
                        </div>
                      </div>
                      <div>
                        {
                          expanedRows.fulltext[row._id] ?
                            <div style={{ marginTop: 15 }}>
                              {
                                row._source?.fulltextMatchedUrls.map((it: any) => (
                                  <div key={it} style={{ fontSize: 14 }}>
                                    <AmplifyLink
                                      target='_blank'
                                      href={ it }
                                    >
                                      { general.ellipsisInMiddle(it, 110) }
                                    </AmplifyLink>
                                  </div>
                                ))
                              }
                            </div> : <></>
                        }
                        {
                          expanedRows.sources[row._id] ?
                            <div style={{ marginTop: 15 }}>
                              {
                                row._source?.foundOnUrls.map((it: any) => (
                                  <div key={it.url} style={{ fontSize: 14, marginTop: 5 }}>
                                    <AmplifyLink
                                      target='_blank'
                                      href={ it?.url }
                                    >
                                      { general.ellipsisInMiddle(it?.url, 110) }{ it?.page_type?.some((type: any) => type?.startsWith('STAFF_')) ? <span style={{ marginLeft: 5 }} className='tag tag-blue'>Staff Page</span> : <></> }
                                    </AmplifyLink>
                                    {
                                      Array.isArray(it?.versions) ?
                                        <Flex gap={5} className="desc-2" alignItems='baseline'>
                                          Scanned on
                                          {
                                            it.versions
                                              .sort((a: any, b: any) => (new Date(b?.created_at) as any) - (new Date(a?.created_at) as any))
                                              .slice(0, 3)
                                              .map((version: any, index: number) => {
                                                return <div key={version?.created_at}>
                                                  {
                                                    new Date(version.created_at).toLocaleDateString('en-US', { year: '2-digit', month: '2-digit', day: '2-digit' })
                                                  }
                                                  {
                                                    version?.screenshot ?
                                                      <span className='clickable' onClick={() => setSelectedScreenshot(version)}> (screenshot)</span> :
                                                      <></>
                                                  }
                                                  {
                                                    index === it.versions.slice(0, 3).length - 1 ? '' : ','
                                                  }
                                                </div>
                                              })
                                          }
                                        </Flex> :
                                        <></>
                                    }
                                  </div>
                                ))
                              }
                            </div> : <></>
                        }
                        {
                          expanedRows.institutions[row._id] ?
                            <div style={{ marginTop: 15 }}>
                              {
                                row._source?.belongsTo.map((it: any) => (
                                  <div key={it?.id} style={{ marginTop: 8 }}>
                                    { ['SCHOOL', 'HOSPITAL'].includes(it?.entityType) && <><InstitutionTypeTag category={it?.entityType} /> <Link to={`/directory/${globalContext.environment.entities.childInstitution.urlSegment}/${it?.id || ''}`}>{ it?.name }</Link>, { general.getStateName(it?.state) }</> }
                                    { ['DISTRICT', 'IDN'].includes(it?.entityType) && <><InstitutionTypeTag category={it?.entityType} /> <Link to={`/directory/${globalContext.environment.entities.parentInstitution.urlSegment}/${it?.id || ''}`}>{ it?.name }</Link>, { general.getStateName(it?.state) }</> }
                                  </div>
                                ))
                              }
                            </div> : <></>
                        }
                      </div>
                      {
                        showTags &&
                          <div style={{ marginTop: 10 }}>
                            <ResourceTags
                              tags={row.tags}
                              tagPool={[]}
                              isEditable={true}
                              resource='CONTACT'
                              resourceId={row._id}
                              onDeleteTag={(deletedTag: string) => {
                                onDeleteTag(row, deletedTag)
                              }}
                              onSaveTag={(savedTag: string) => {
                                onSaveTag(row, savedTag)
                              }}
                              onTagClicked={(clickedTag: string) => {
                                // const updatedTags = newSearchParams?.includedTags?.values
                                // if (!updatedTags?.includes(clickedTag)) updatedTags?.push(clickedTag)
                                // setNewSearchParams(prevState => new ContactsSearchParams({
                                //   ...prevState?.raw(),
                                //   ...{ includedTags: updatedTags }
                                // }))
                              }}
                            />
                          </div>
                      }
                    </div>
                    <div style={{ width: '20px', marginLeft: 10 }}>
                      {
                        showFavorite &&
                          <BiSolidCheckCircle
                            title={row?.tags?.includes('verified') ? '' : 'Mark as verified'}
                            className={`favorite-button ${row?.tags?.includes('verified') ? 'is-favorite' : 'is-not-favorite'}`}
                            onClick={async () => {
                              if (row?.tags?.includes('verified')) {
                                try {
                                  await general.deleteTag({ resource: 'CONTACT', resourceId: row._id, tag: 'verified' })
                                  onDeleteTag(row, 'verified')
                                } catch (err) {
                                  console.log(err)
                                  toast.warn('Removing contact from verified failed, please try again')
                                }
                              } else {
                                try {
                                  await general.saveTag({ resource: 'CONTACT', resourceId: row._id, tag: 'verified' })
                                  onSaveTag(row, 'verified')
                                } catch (err) {
                                  console.log(err)
                                  toast.warn('Adding contact to verified failed, please try again')
                                }
                              }
                            }}
                          />
                      }
                      {
                        showEdit &&
                          <BiSolidPencil
                            className='edit-button'
                            onClick={async () => {
                              setFormOverrideData({
                                name: row._source.name,
                                title: row._source.title,
                                email: row._source.email,
                                phone: row._source.phone,
                              });
                              setSelectedContactForOverride(row)
                            }}
                          />
                      }
                    </div>
                  </div>
                ))
              }
            </div>
          </div>
        </>
    }
    <ReactModal
      isOpen={!!selectedScreenshot}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
      onRequestClose={() => setSelectedScreenshot(undefined)}
      style={general.getSliderDefaults({ width: '900px' })}
      closeTimeoutMS={200}
      parentSelector={general.getModalRoot}
    >
      <div className='screenshot-wrapper'>
        {/* <div className="bounding-boxes"> */}
          {
            selectedScreenshot?.boxes?.map((box, index) => {
              return <>
                {
                  box?.boxes?.map((bb, index) => {
                    return <>
                      <div className="bounding-box" style={getBoundingBoxPosition(selectedScreenshot.screenshot_size, bb)}></div>
                    </>
                  })
                }
              </>
            })
          }
        {/* </div> */}
        <Image src={`https://d3lox7nr5xy0y9.cloudfront.net/url/${selectedScreenshot?.screenshot}`} alt='Screenshot' />
      </div>
    </ReactModal>
    <ReactModal
      isOpen={!!selectedContactForOverride}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
      onRequestClose={() => setSelectedContactForOverride(null)}
      style={general.getModalDefaults()}
      parentSelector={general.getModalRoot}
    >
      <div style={{ padding: 20 }}>
        <h3 style={{ marginTop: 0 }}>Edit this contact</h3>
        <div style={{ marginBottom: 20 }}>
          <div className='amplify-label' style={{ fontSize: 15 }}>Name</div>
          <Input
            value={formOverrideData.name}
            style={{ marginTop: 5 }}
            onChange={(event) => {
              setFormOverrideData({
                ...formOverrideData,
                name: event.target.value
              })
            }}
          />
        </div>
        <div style={{ marginBottom: 20 }}>
          <div className='amplify-label' style={{ fontSize: 15 }}>Title</div>
          <Input
            value={formOverrideData.title}
            style={{ marginTop: 5 }}
            onChange={(event) => {
              setFormOverrideData({
                ...formOverrideData,
                title: event.target.value
              })
            }}
          />
        </div>
        <div style={{ marginBottom: 20 }}>
          <div className='amplify-label' style={{ fontSize: 15 }}>Email</div>
          <Input
            value={formOverrideData.email}
            style={{ marginTop: 5 }}
            type='email'
            onChange={(event) => {
              setFormOverrideData({
                ...formOverrideData,
                email: event.target.value
              })
            }}
          />
        </div>
        <div>
          <div className='amplify-label' style={{ fontSize: 15 }}>Phone</div>
          <Input
            value={formOverrideData.phone}
            style={{ marginTop: 5 }}
            onChange={(event) => {
              setFormOverrideData({
                ...formOverrideData,
                phone: event.target.value
              })
            }}
          />
        </div>
        <Button
          variation='primary'
          isFullWidth={true}
          disabled={isSavingSelectedContactForOverride}
          isLoading={isSavingSelectedContactForOverride}
          loadingText='Saving...'
          style={{ marginTop: 20 }}
          onClick={saveOverride}
        >
          Save
        </Button>
      </div>
    </ReactModal>
  </>;
}

export default forwardRef(ContactList);