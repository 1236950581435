import { Link as AmplifyLink } from "@aws-amplify/ui-react"
import { FaExternalLinkAlt } from "react-icons/fa";

interface SchoolAddressProps {
  isLink?: boolean;
  row: any;
}

export default function SchoolAddress({ isLink = false, row }: SchoolAddressProps) {
  const address = row.locAddressPhys
  return <>
    {
      isLink ?
        <AmplifyLink target='_blank' href={'https://www.google.com/maps/search/' + address}>{ address } <FaExternalLinkAlt size={11} /></AmplifyLink> :
        address
    }
  </>;
}
