import { useContext, useEffect, useState } from 'react';
import { GlobalContext, GlobalContextProps } from '../global-context';
import general from '../utils/general';
import LoadingBars from '../custom-components/LoadingBars';
import { useParams, Link } from 'react-router-dom';
import { Card, Text, Link as AmplifyLink, Flex } from '@aws-amplify/ui-react';
import NA from '../custom-components/NA';
import TitleCategoryTag from '../custom-components/TitleCategoryTag';
import InstitutionTypeTag from '../custom-components/InstitutionTypeTag';
import { toast } from 'react-toastify';
import EmailAddress from '../custom-components/EmailAddress';
import { post } from 'aws-amplify/api';
import { FaExternalLinkAlt } from 'react-icons/fa';

export function Contact(props: any) {

  const globalContext = useContext(GlobalContext);
  general.setTitle(document, 'Contact', globalContext);

  const { id } = useParams()
  const [uid] = useState(id);
  const [response, setResponse] = useState<any>(null);
  const [isLoadingContacts, setIsLoadingContacts] = useState(false);

  async function loadContacts() {
    setIsLoadingContacts(true)
    const headers = await general.getAuthorizationHeaders()
    const body = { action: 'DB_GET', data: { entity: 'contacts', id: uid as string } }
    try {
      const postOperation = post({
        apiName: 'apiMain',
        path: '/data',
        options: {
          headers,
          body
        }
      });
      const response = await postOperation.response
      const bodyJson = await response.body.json() as any
      setResponse(bodyJson);
      general.setTitle(document, bodyJson.data._source.name, globalContext);
    } catch (err) {
      toast.warn('Failed to load contact, please try again', { autoClose: false })
    } finally {
      setIsLoadingContacts(false)
    }
  }

  useEffect(() => {
    loadContacts();
  }, [props.id]);

  return <GlobalContext.Consumer>
    {
      (globalContextProps: GlobalContextProps) => <>
        <div className="content content-800">
          {
            !response ?
              <LoadingBars style={{ margin: '20px 0' }} numberOfRows={1} /> :
              <Flex alignItems="center" style={{ marginBottom: 20 }}>
                <div className={`avatar avatar-${general.getCSSFromId(response.data._id)}`}>
                  { general.getInitials(response.data._source?.name) }
                </div>
                <h2>{ response.data._source?.name || '<Contact\'s name not available>' }</h2>
              </Flex>
          }
          <Card variation='outlined' style={{fontSize: 14}}>
            {
              !response ?
                <LoadingBars numberOfRows={3} /> :
                !response?.data ?
                  <div style={{ marginTop: 20, color: 'var(--amplify-colors-neutral-80)', textAlign: 'center' }}>No contact found</div> :
                  <>
                    <div style={{marginBottom: '10px'}}><strong style={{ width: 100, display: 'inline-block' }}>Job title: </strong>{ response.data._source['title'] ? <>{ response.data._source['title'] } <TitleCategoryTag titleCategory={response.data._source['titleCategory']} /></> : <NA /> }</div>
                    <div style={{marginBottom: '10px'}}><strong style={{ width: 100, display: 'inline-block' }}>Email: </strong><EmailAddress email={response.data._source['email']} emailDomainCount={response.data._source['emailDomainCount']} emailVerificationStatus={response.data._source['emailVerificationStatus']} emailLastVerifiedAt={response.data._source['emailLastVerifiedAt']} /></div>
                    <div><strong style={{ width: 100, display: 'inline-block' }}>Phone: </strong>{ response.data._source['phone'] || <NA /> }</div>
                    {
                      response.data._source?.['bios'] && <div>
                        {
                          response.data._source['bios'].map((bio: any) => {
                            return <div key={bio?.created_at} style={{ marginTop: 20 }}>
                              { bio?.bio } (<AmplifyLink target='_blank' href={bio?.url}>source</AmplifyLink>)
                            </div>
                          })
                        }
                      </div>
                    }
                  </>
            }
          </Card>
          <div style={{ marginTop: 20 }}>
            {
              !response ?
                <LoadingBars numberOfRows={1} style={{ marginBottom: 20 }} /> :
                !response?.data ?
                  <></> :
                  <Text
                    variation="primary"
                    as="p"
                    fontWeight={400}
                    fontStyle="italic"
                    fontSize='small'
                  >
                    <strong>{ response.data._source?.name || 'Contact' }</strong> is associated with { response.data._source?.belongsTo?.length || 'these' } institution{ response.data._source?.belongsTo?.length === 1 ? '' : 's' }
                  </Text>
            }
            {
              !response ?
                <LoadingBars /> :
                  <Card variation='outlined' style={{fontSize: 14, marginTop: 5}}>
                    {
                      !response.data._source?.belongsTo?.length ?
                        <></> :
                        <>
                          {
                            general
                              .deduplicateArrayByKey(response.data._source.belongsTo, 'id')
                              ?.sort((a: any, b: any) => a?.entityType > b?.entityType ? 1 : 0)
                              .map((item: any) =>
                                <div style={{ marginBottom: 15 }}>
                                  { ['SCHOOL', 'HOSPITAL'].includes(item?.entityType) && <><InstitutionTypeTag category={item?.entityType} /> <Link to={`/directory/${globalContext.environment.entities.childInstitution.urlSegment}/${item?.id}`}>{ item?.name }</Link>, { general.getStateName(item?.state) }</> }
                                  { ['DISTRICT', 'IDN'].includes(item?.entityType) && <><InstitutionTypeTag category={item?.entityType} /> <Link to={`/directory/${globalContext.environment.entities.parentInstitution.urlSegment}/${item?.id}`}>{ item?.name }</Link>, { general.getStateName(item?.state) }</> }
                                  <div style={{ marginLeft: 30, marginTop: 5, listStyleType: 'square' }} className='desc-3'>
                                    {
                                      (item?.associations || [])
                                        .map((it: any) => {
                                          return <>
                                            {
                                              ['EMAIL_DOMAIN_BELONGS_TO_DISTRICT', 'EMAIL_DOMAIN_BELONGS_TO_ONE_SCHOOL', 'EMAIL_DOMAIN_BELONGS_TO_INSTITUTION'].includes(it?.type) &&
                                                <li>
                                                  Email domain <i>{ it?.properties?.domain }</i> is associated with this { item?.entityType === 'SCHOOL' ? 'school' : 'district' }
                                                </li>
                                            }
                                            {
                                              it?.type === 'URL_PREFIX_MATCH' &&
                                                <li>
                                                  URLs beginning with <i>{ it?.properties?.prefix }</i> the contact was found on are associated with this { item?.entityType === 'SCHOOL' ? 'school' : 'district' }
                                                </li>
                                            }
                                            {
                                              it?.type === 'LINKED_TO' &&
                                                <li>
                                                  { item?.entityType === 'SCHOOL' ? 'School' : 'District' } website linked to a URL <i>{ it?.properties?.url }</i> this contact was found on
                                                </li>
                                            }
                                            {
                                              it?.type === 'MANUAL' &&
                                                <li>
                                                  Manually associated with this { item?.entityType === 'SCHOOL' ? 'school' : 'district' }
                                                </li>
                                            }
                                          </>
                                        })
                                    }
                                  </div>
                                </div>
                              )
                          }
                        </>
                    }
                  </Card>
            }
          </div>
          <div style={{ marginTop: 20 }}>
            {
              !response ?
                <LoadingBars numberOfRows={1} style={{ marginBottom: 20 }} /> :
                !response.data._source?.foundOnUrls?.length ?
                  <></> :
                  <Text
                    variation="primary"
                    as="p"
                    fontWeight={400}
                    fontStyle="italic"
                    fontSize='small'
                  >
                    <strong>{ response.data._source?.name || 'Contact' }</strong> was found on { response.data._source?.foundOnUrls?.length || 'these' } URL{ response.data._source?.foundOnUrls?.length === 1 ? '' : 's' }
                  </Text>
            }
            {
              !response ?
                <LoadingBars /> :
                !response.data._source?.foundOnUrls?.length ?
                  <></> :
                  <Card variation='outlined' style={{fontSize: 14, marginTop: 5}} >
                    {
                      response.data._source.foundOnUrls.map((item: any) =>
                        <div key={item.url} style={{ marginBottom: 8 }}>
                          <AmplifyLink
                            target='_blank'
                            href={ item?.url }
                          >
                            { general.replaceMiddleWithDots(item?.url, 60, 25) } <FaExternalLinkAlt size={11} />
                          </AmplifyLink>{ item?.domain?.engine && <span className='tag tag-gray tag-tiny' data-tooltip-id="main-tooltip" data-tooltip-content={`This website is powered by ${item?.domain?.engine}`} style={{ marginLeft: 5 }}>{ item?.domain?.engine }</span> }
                        </div>
                      )
                    }
                  </Card>
            }
          </div>
        </div>
      </>
    }
  </GlobalContext.Consumer>
}
