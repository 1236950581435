import { useContext, useEffect, useRef, useState } from 'react';
import { GlobalContext, GlobalContextProps } from '../global-context';
import general from '../utils/general';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { post } from 'aws-amplify/api';
import { Link as AmplifyLink, Button, Card, Flex, Loader, Table, TableCell, TableRow } from '@aws-amplify/ui-react';
import LoadingBars from '../custom-components/LoadingBars';
import { Link } from 'react-router-dom';
import { FaChevronDown, FaChevronRight, FaExternalLinkAlt } from 'react-icons/fa';
import NA from '../custom-components/NA';
import ReactModal from 'react-modal';
import ScanUrlProgressIndicator from '../custom-components/ScanUrlProgressIndicator';
import { webSocketUrl } from '../utils/Constants';

export function ProspectorJob(props: any) {

  const globalContext = useContext(GlobalContext);
  general.setTitle(document, 'Prospector', globalContext);

  const { id } = useParams()
  const [isLoading, setIsLoading] = useState(true);
  const [showOpenAIResults, setShowOpenAIResults] = useState(null);
  const [prospectorJob, setProspectorJob] = useState<any>();
  const [expandedUrlVersions, setExpandedUrlVersions] = useState<any>({});
  const [selectedUrlIndex, setSelectedUrlIndex] = useState<number>(0);
  const [selectedUrl, setSelectedUrl] = useState<any>();
  const [contacts, setContacts] = useState();

  useEffect(() => {
    if (globalContext?.authInfo?.teamId && id) {
      loadProspectorJob()
    }
  }, [globalContext.authInfo?.teamId, id])

  useEffect(() => {
    if (prospectorJob && !isNaN(selectedUrlIndex)) {
      const selectedUrl = prospectorJob?.urls?.[selectedUrlIndex]
      if (selectedUrl) {
        setSelectedUrl(selectedUrl)
      }
    }
  }, [prospectorJob, selectedUrlIndex])

  async function loadProspectorJob() {
    try {
      if (typeof id !== 'string') return
      
      setIsLoading(true)
      const headers = await general.getAuthorizationHeaders()

      const postOperation = post({
        apiName: 'apiMain',
        path: '/data',
        options: {
          headers,
          body: {
            action: 'GET_PROSPECTOR_JOB',
            data: {
              id
            }
          }
        }
      });
      const response = await postOperation.response
      const bodyJson = await response.body.json() as any
      setProspectorJob(bodyJson?.data)
      setContacts(bodyJson?.data?.contacts)
      if (bodyJson?.data?.urls?.[0]?.versions?.[0]?.id) {
        setExpandedUrlVersions({
          [bodyJson?.data?.urls?.[0]?.versions?.[0]?.id]: true
        })
      }
    } catch (err) {
      toast.warn(`Something went wrong loading scan job`)
    } finally {
      setIsLoading(false)
    }
  }

  const [ws, setWs] = useState<WebSocket>();
  const responseDataRef = useRef<any>(null);

  useEffect(() => {
    if (id) {
      general.getAuthorizationHeaders().then((headers) => {
        const token = headers.Authorization;

        const ws = new WebSocket(`${webSocketUrl}?type=URL_SCANNING_SOCKET&value=${id}&t=${token}`);

        setWs(ws);
        responseDataRef.current = ws;

        ws.onopen = () => {
          console.log('Websocket connected');
        };

        ws.onmessage = (event) => {
          try {
            console.log('Websocket message', event)
            const websocketResponse = JSON.parse(event.data);
            if (websocketResponse) {
              console.log('setProspectorJob', websocketResponse)
              setProspectorJob(websocketResponse)
            }
          } catch (err) {
            console.log(err)
          }
        };

        ws.onclose = () => {
          console.log('Websocket closed');
        };

        ws.onerror = (error) => {
          console.error('Websocket error:', error);
        };
      });

      return () => {
        const ws = responseDataRef.current
        if (ws.readyState === WebSocket.OPEN || ws.readyState === WebSocket.CONNECTING) {
          console.log('Closing websocket')
          ws.close();
          console.log('Closed websocket')
        }
      };
    }
  }, [id]);

  function copyToClipboard(gptParsedContacts: any[]) {
    const delimiter = '\t'
    const str = gptParsedContacts
      .map((contact) =>
        `${contact?.name?.value || ''}${delimiter}${(contact?.email?.isInText && general.validateEmail(contact?.email?.value)) ? contact?.email?.value : ''}${delimiter}${(contact?.phone?.isInText && contact?.phone?.value) ? contact?.phone?.value : ''}${delimiter}${contact?.title?.value || ''}`
      )
      .join('\n')
    navigator.clipboard.writeText(str).then(() => {
      alert('Copied to clipboard!');
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  };
  
  return <GlobalContext.Consumer>
    {
      (globalContextProps: GlobalContextProps) => <>
        <div className="content content-1200">
          <div className='marquee'>Disclaimer: This tool is currently in public beta and might produce incomplete results. Email us to share feedback.</div>
          <Flex justifyContent='space-between' alignItems='center' style={{ marginTop: 30, marginBottom: 5 }}>
            <h1 style={{ margin: 0 }}>
              <Link to={`/prospector`}>Prospector</Link> / { id && id.substring(0, 8) }
            </h1>
          </Flex>
        </div>
        {
          prospectorJob?.meta?.status === 'RUNNING' ?
            <div className="prospector-loading" style={{ marginTop: 30 }}>
              <div className="label">
                <Loader />
                <div>Working on { prospectorJob?.urls?.length } URL{ prospectorJob?.urls?.length === 1 ? '' : 's' }...</div>
              </div>
            </div>
            : <></>
        }
        <div className="content content-1200">
          <Card variation='outlined' style={{ padding: 0 }}>
            {
              isLoading
                ? <LoadingBars style={{ margin: 15 }} />
                : <div>
                    {
                      (prospectorJob?.urls || [])?.map((url: any, index: number) =>
                        <div key={index} className='split'>
                          {
                            url?.status !== 'INVALID'
                              ? <>
                                  <div onClick={() => selectedUrlIndex === index ? setSelectedUrlIndex(-1) : setSelectedUrlIndex(index)} className={`split-link-row ${index === selectedUrlIndex ? 'active' : ''}`}>
                                    <div>
                                      { general.replaceMiddleWithDots(url?.url, 26, 26, '...') } <AmplifyLink target='_blank' href={url?.url}>link <FaExternalLinkAlt size={11} /></AmplifyLink>
                                    </div>
                                    <ScanUrlProgressIndicator url={url} />
                                  </div>
                                  {
                                    selectedUrlIndex === index ?
                                      <div className='split-link-content'>
                                        <div>
                                          {
                                            !selectedUrl?.versions?.length
                                              ? <></>
                                              : <div>
                                                  <h4 style={{ marginTop: 0, marginBottom: 10 }}>Scans{ selectedUrl?.versions && <span style={{ color: 'var(--amplify-colors-neutral-80)' }}>{ ` (${selectedUrl?.versions?.length})` }</span> }</h4>
                                                  {
                                                    selectedUrl?.versions?.map((version: any, index: number) => {
                                                      return <div style={{ marginBottom: index === selectedUrl?.versions?.length - 1 ? 0 : 15 }}>
                                                        <Flex justifyContent='flex-start' alignContent='baseline'>
                                                          <div
                                                            style={{ margin: 0, cursor: 'pointer', fontSize: 14 }}
                                                            onClick={() => setExpandedUrlVersions({
                                                              ...expandedUrlVersions,
                                                              [version.id]: !expandedUrlVersions?.[version.id]
                                                            })}
                                                          >
                                                            <strong>{ expandedUrlVersions?.[version.id] ? <FaChevronDown size={10} style={{ marginRight: 5 }} /> : <FaChevronRight size={10} style={{ marginRight: 5 }} /> }</strong> Scanned { general.daysAgo(version?.created_at) }
                                                          </div>
                                                          {
                                                            version?.id_run === id
                                                              ? <div style={{ marginLeft: -10, marginTop: 1 }} className='tag tag-green'>
                                                                  Scanned during this run
                                                                </div>
                                                              : <div style={{ marginLeft: -10, marginTop: 1 }} className='tag tag-gray'>
                                                                  Scanned during previous runs
                                                                </div>
                                                          }
                                                        </Flex>
                                                        <div style={{ marginTop: 5, display: expandedUrlVersions?.[version.id] ? 'block' : 'none' }}>
                                                          <div className='desc-5' style={{ marginLeft: 20, marginBottom: 10 }}>ID: { version?.id || <NA /> }</div>
                                                          {
                                                            version?.gptParsedContacts?.length
                                                              ? <div>
                                                                  <Flex style={{ marginBottom: 10, marginTop: -30 }} justifyContent='flex-end'>
                                                                    <Button onClick={() => copyToClipboard(version?.gptParsedContacts)} className='tiny'>Copy to Clipboard</Button>
                                                                  </Flex>
                                                                  <Table size='small'>
                                                                    <TableRow>
                                                                      <TableCell style={{ fontWeight: 'bold' }}>Name</TableCell>
                                                                      <TableCell style={{ fontWeight: 'bold' }}>Email</TableCell>
                                                                      <TableCell style={{ fontWeight: 'bold' }}>Phone</TableCell>
                                                                      <TableCell style={{ fontWeight: 'bold' }}>Title</TableCell>
                                                                    </TableRow>
                                                                    {
                                                                      version?.gptParsedContacts?.map((contact: any) => {
                                                                        return <TableRow>
                                                                          <TableCell>{ contact?.name?.value || <NA /> }</TableCell>
                                                                          <TableCell>
                                                                            {
                                                                              (contact?.email?.isInText && general.validateEmail(contact?.email?.value))
                                                                                ? contact?.email?.value
                                                                                : contact?.guessBasedOnName?.email
                                                                                  ? contact?.guessBasedOnName?.email
                                                                                  : contact?.guessEmail
                                                                                    ? contact?.guessEmail
                                                                                    : <NA />
                                                                            }
                                                                          </TableCell>
                                                                          <TableCell>{ (contact?.phone?.isInText && contact?.phone?.value) ? contact?.phone?.value : <NA /> }</TableCell>
                                                                          <TableCell>{ contact?.title?.value || <NA /> }</TableCell>
                                                                        </TableRow>
                                                                      })
                                                                    }
                                                                  </Table>
                                                                </div>
                                                              : <></>
                                                          }
                                                        </div>
                                                      </div>
                                                    })
                                                  }
                                                </div>
                                          }
                                        </div>
                                        <Flex style={{ marginTop: 20 }}>
                                          <div className='desc-5'>URL discovered { general.daysAgo(selectedUrl?.createdAt) || <NA /> }</div>
                                          <div className='desc-5'>ID: { selectedUrl?.id || <NA /> }, Domain: { selectedUrl?.domain || <NA /> }</div>
                                        </Flex>
                                      </div>
                                      : <></>
                                  }
                                </>
                              : <div>{ url?.url } (invalid URL)</div>
                          }
                        </div>
                      )
                    }
                  </div>
            }
          </Card>
        </div>
        <ReactModal
          isOpen={!!showOpenAIResults}
          shouldCloseOnEsc={true}
          shouldCloseOnOverlayClick={true}
          onRequestClose={() => setShowOpenAIResults(null)}
          style={{
            content: {
              top: '50%',
              left: '50%',
              width: '2000px',
              height: '80%',
              marginRight: '-50%',
              transform: 'translate(-50%, -50%)',
              border: 'none'
            },
            overlay: {
              background: 'rgba(72, 72, 101, 0.1)'
            }
          }}
          parentSelector={general.getModalRoot}
          preventScroll={false}
        >
          {
            showOpenAIResults && <div>
              <pre>
                {
                  JSON.stringify(showOpenAIResults, null, 2)
                }
              </pre>
            </div>
          }
        </ReactModal>
      </>
    }
  </GlobalContext.Consumer>
}
