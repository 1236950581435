import { Flex } from "@aws-amplify/ui-react";
import './GradeSpan.scss';
import general from "../utils/general";
import NA from "./NA";

export default function GradeSpan(input: any) {
  const { row } = input;

  const theme = input?.theme === 'dark' ? 'dark' : 'light'

  const grades = {
    'gNurseryOfrd': 'NS',
    'gPkOfrd': 'PK',
    'gKgOfrd': 'KG',
    'g1Ofrd': '1',
    'g2Ofrd': '2',
    'g3Ofrd': '3',
    'g4Ofrd': '4',
    'g5Ofrd': '5',
    'g6Ofrd': '6',
    'g7Ofrd': '7',
    'g8Ofrd': '8',
    'g9Ofrd': '9',
    'g10Ofrd': '10',
    'g11Ofrd': '11',
    'g12Ofrd': '12',
    'g13Ofrd': '13'
  };
  const gradesHumanReadable: any = {
    'NS': 'Nursery',
    'PK': 'Pre-K',
    'KG': 'Kindergarten',
    '1': '1st grade',
    '2': '2nd grade',
    '3': '3rd grade',
    '4': '4th grade',
    '5': '5th grade',
    '6': '6th grade',
    '7': '7th grade',
    '8': '8th grade',
    '9': '9th grade',
    '10': '10th grade',
    '11': '11th grade',
    '12': '12th grade',
    '13': '13th grade'
  }
  const entries: [string, string][] = Object.entries(grades);
  let from = null;
  let to = null;
  let i = 0;
  for (const grade of entries) {
    if (from === null && row[grade[0]] === true) {
      from = entries[i][1];
    }
    if (row[grade[0]] === true) {
      to = entries[i][1]
    }
    i ++;
  }
  return <>
    <Flex className={`grade-span theme-${theme}`} alignItems={"center"} gap={2}>
      {
        entries
          .map(grade =>
            <div
              key={general.getRandomString(5)}
              data-tooltip-id="main-tooltip"
              data-tooltip-content={`${gradesHumanReadable[grade[1]]} offered`}
              data-tooltip-hidden={!row[grade[0]]}
              className={ row[grade[0]] ? 'grade grade-offered' : 'grade' }
            ></div>
          )
      }
      <span style={{ marginLeft: 10 }}>
        { from && to ? <span>{ from } - { to }</span> : <NA /> }
      </span>
    </Flex>
  </>
}
