import { Button, CheckboxField, Flex, Loader, Menu, MenuButton } from '@aws-amplify/ui-react';
import { forwardRef, useContext, useEffect, useState } from 'react';
import LoadingBars from "./LoadingBars";
import { BiChevronDown } from 'react-icons/bi';
import general from '../utils/general';
import { GlobalContext } from '../global-context';
import { SortOptions } from '../utils/CustomTypes'
import { TableRowDistrict, TableRowHeaderDistrict } from '../custom-components/TableRows/TableRowDistrict'
import { TableRowIDN, TableRowHeaderIDN } from '../custom-components/TableRows/TableRowIDN'
import { TableRowHeaderSchool, TableRowSchool } from '../custom-components/TableRows/TableRowSchool'
import { TableRowHospital, TableRowHeaderHospital } from '../custom-components/TableRows/TableRowHospital'
import { Entity } from '../environment';

const InstitutionsList = ({
  institutionsSearchResponse,
  isLoading,
  isExportingToCsv,
  onSort,
  onExportToCsv,
  showSortButton = true,
  showExportButton = true,
  sortOptions,
  entity,
}: {
  institutionsSearchResponse: any,
  isLoading: boolean,
  isExportingToCsv: boolean,
  onSort: (sortBy: string, sortDir: string) => void,
  onExportToCsv: (ids?: any[]) => void,
  showSortButton?: boolean,
  showExportButton?: boolean,
  sortOptions: SortOptions[],
  entity: Entity
}, ref: any) => {

  const globalContext = useContext(GlobalContext);
  const [selectedInstitutions, setSelectedInstitutions] = useState<{[key: string]: any}>({});
  const [selectedInstitutionsCount, setSelectedInstitutionsCount] = useState(0);
  const [expanedRows, setExpandedRows] = useState<{ sources: any, institutions: any, fulltext: any }>({ sources: {}, institutions: {}, fulltext: {} });

  useEffect(() => {
    const checkedItemsCountLocal = general.getTrueKeys(selectedInstitutions);
    setSelectedInstitutionsCount(checkedItemsCountLocal.length);
  }, [selectedInstitutions])

  function handleCheckboxChange(row: any, event: any) {
    const itemId = event.target.id;
    const isChecked = event.target.checked;
    setSelectedInstitutions(prevState => ({
      ...prevState,
      [itemId]: isChecked ? row : null
    }));
  }

  async function toggleAll(event: any) {
    const contactIds: any = {}
    for (const item of institutionsSearchResponse?.data?.hits?.hits) contactIds[item._id] = item;
    if (event.target.checked) {
      // If checked, add all contacts to selectedContacts
      setSelectedInstitutions(prevState => ({
        ...prevState,
        ...contactIds
      }));
    } else {
      // If unchecked, remove all contacts from selectedContacts
      const updatedContacts = Object.assign({}, selectedInstitutions);
      for (const key in contactIds) {
        delete updatedContacts[key];
      }
      setSelectedInstitutions(prevState => updatedContacts);
    }
  }

  return <>
    {
      isLoading ?
        <LoadingBars numberOfRows={15} /> :
        !institutionsSearchResponse?.data?.hits?.hits?.length ?
          <></> :
          <>
            <div className="main-card">
              <div className='results-row results-header'>
                <div className="results-row-left-side" style={{ margin: '5px 4px 4px 4px' }}>
                  <CheckboxField
                    label=""
                    name="subscribe"
                    value="yes"
                    size="large"
                    onChange={toggleAll}
                  />
                </div>
                <div className="results-row-content">
                  <Flex
                    alignItems='center'
                    justifyContent='space-between'
                  >
                    <div className='desc-3'>
                      Found { general.addCommasToNumber(institutionsSearchResponse?.data?.hits?.total?.value) }{institutionsSearchResponse?.data?.hits?.total?.value === 10000 ? '+' : ''} {institutionsSearchResponse?.data?.hits?.total?.value === 1 ? 'match' : 'matches'}
                      {
                        selectedInstitutionsCount > 0 && <span>, { selectedInstitutionsCount } item{ selectedInstitutionsCount === 1 ? '' : 's' } selected</span>
                      }
                    </div>
                    <Flex direction='row' gap={5}>
                      <div>
                        {
                          isExportingToCsv &&
                            <Loader style={{ marginRight: 10, transform: 'translateY(7px)' }} />
                        }
                      </div>
                      {
                        showSortButton &&
                          <Menu
                            size='small'
                            alignItems='start'
                            trigger={
                              <MenuButton
                                size='small'
                                className='tiny'
                              >
                                Sort <BiChevronDown style={{ marginLeft: 5, marginRight: -3, fontSize: 15 }} />
                              </MenuButton>
                            }
                          >
                            {
                              sortOptions.map((option) => (
                                <Button
                                  key={option.sortBy + option.sortDir}
                                  className='menu-item'
                                  onClick={onSort.bind(null, option.sortBy, option.sortDir)}
                                >
                                  { option.name }
                                </Button>
                              ))
                            }
                          </Menu>
                      }
                      {
                        showExportButton &&
                          <Menu
                            size='small'
                            alignItems='start'
                            trigger={
                              <MenuButton
                                size='small'
                                className='tiny'
                              >
                                Export <BiChevronDown style={{ marginLeft: 5, marginRight: -3, fontSize: 15 }} />
                              </MenuButton>
                            }
                          >
                            <Button
                              key='exportselected'
                              disabled={selectedInstitutionsCount === 0 || isExportingToCsv}
                              className='menu-item'
                              onClick={() => {
                                onExportToCsv(Object.keys(selectedInstitutions))
                              }}
                            >
                              Export Selected to CSV ({ selectedInstitutionsCount })
                            </Button>
                            <Button
                              key='exportall'
                              disabled={isExportingToCsv}
                              className='menu-item'
                              onClick={() => {
                                onExportToCsv()
                              }}
                            >
                              Export All to CSV
                            </Button>
                          </Menu>
                      }
                    </Flex>
                  </Flex>
                </div>
              </div>
              { entity === Entity.SCHOOL && <TableRowHeaderSchool /> }
              { entity === Entity.DISTRICT && <TableRowHeaderDistrict /> }
              { entity === Entity.HOSPITAL && <TableRowHeaderHospital /> }
              { entity === Entity.IDN && <TableRowHeaderIDN /> }
              <div className="results-table">
                {
                  institutionsSearchResponse?.data?.hits?.hits?.map((row: any) => (
                    <div
                      key={row._id}
                      className="results-row results-content"
                    >
                      <div className="results-row-left-side">
                        <div style={{ margin: '2px 4px' }}>
                          <CheckboxField
                            label=""
                            name="subscribe"
                            value="yes"
                            id={row._id}
                            size="large"
                            checked={selectedInstitutions[row._id] || ''}
                            onChange={(event) => {
                              handleCheckboxChange(row, event)
                              event.stopPropagation();
                            }}
                          />
                        </div>
                      </div>
                      { entity === Entity.SCHOOL && <TableRowSchool row={row} /> }
                      { entity === Entity.DISTRICT && <TableRowDistrict row={row} /> }
                      { entity === Entity.HOSPITAL && <TableRowHospital row={row} /> }
                      { entity === Entity.IDN && <TableRowIDN row={row} /> }
                    </div>
                  ))
                }
              </div>
            </div>
          </>
    }
  </>
}

export default forwardRef(InstitutionsList);
