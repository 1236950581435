import { Card, Link as AmplifyLink, Flex, Menu, MenuButton, Button, } from '@aws-amplify/ui-react';
import { GlobalContext } from '../global-context';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import general from '../utils/general';
import { toast } from 'react-toastify';

const boces = require('../boces.json')

export function ServiceAgencies(props: any) {

  const [isBocesExpanded, setIsBocesExpanded] = useState<{ [key: string]: boolean }>({});

  return (
    <GlobalContext.Consumer>
      {(globalContextProps) =>
        <>
          <div className="content content-800">
            <h1>Service Agencies</h1>
            <Card variation='outlined' style={{fontSize: 14}}>
              <div className='desc-3'>Educational Service Agencies (ESAs), including LEAs (Local Education Agencies) and BOCES (Boards of Cooperative Educational Services), are regional organizations supporting multiple school districts. These agencies facilitate collaboration, share expertise, and provide specialized programs to enhance education quality across diverse communities, ensuring equitable access to high-quality education.</div>
            </Card>
            <Flex justifyContent='flex-end' style={{ marginTop: 20 }}>
              <Menu
                size='small'
                alignItems='start'
                trigger={
                  <MenuButton
                    size='small'
                    className='tiny'
                  >
                    Export <BiChevronDown style={{ marginLeft: 5, marginRight: -3, fontSize: 15 }} />
                  </MenuButton>
                }
              >
                <Button
                  key='exportserviceagencies'
                  className='menu-item'
                  onClick={async () => {
                    try {
                      await general.downloadUrl('https://d26bappwwpc7vx.cloudfront.net/edulead-service-agencies.csv', 'edulead-service-agencies.csv')
                    } catch (err) {
                      toast.warn(general.getErrorMessage('Something went wrong exporting to a CSV', err))
                    }
                  }}
                >
                  Export All Service Agencies to CSV
                </Button>
                <Button
                  key='exportserviceagenciescontacts'
                  className='menu-item'
                  onClick={async () => {
                    try {
                      await general.downloadUrl('https://d26bappwwpc7vx.cloudfront.net/edulead-service-agencies-contacts.csv', 'edulead-service-agencies-contacts.csv')
                    } catch (err) {
                      toast.warn(general.getErrorMessage('Something went wrong exporting to a CSV', err))
                    }
                  }}
                >
                  Export All Contacts to CSV
                </Button>
              </Menu>
            </Flex>
            <div className="main-card" style={{ marginTop: 10 }}>
              <div className='results-subheader' style={{ justifyContent: 'flex-start' }}>
                <div style={{ width: '400px' }}>Name</div>
                <div style={{ width: '200px' }}># of service agencies</div>
                {/* <div style={{ width: '180px' }}>District revenue</div> */}
              </div>
              <div className="results-table">
                {
                  boces.map((boces: any) => {
                    return (
                      <div className='boces-row' key={boces.name}>
                        <div className='boces-header' onClick={() => setIsBocesExpanded({ ...isBocesExpanded, [boces.name]: !isBocesExpanded[boces.name] })}>
                          <div style={{ width: '400px', display: 'flex', alignItems: 'center' }}>
                            <div className='desc-6'>{boces.name}</div>
                            {/* <Link to={`/service-agencies/${boces.id}`} className='desc-6'>{boces.name}</Link> */}
                            {/* <div className="tag tag-neutral" style={{ margin: '2px 0 0 10px' }}>{boces.state}</div> */}
                          </div>
                          <div style={{ width: '290px' }}>{boces.children.length}</div>
                          <div style={{ width: 15, transform: 'translateY(3px)' }}>
                            {
                              isBocesExpanded?.[boces.name] ?
                                <BiChevronUp fontSize={20} /> :
                                <BiChevronDown fontSize={20} />
                            }
                          </div>
                        </div>
                        {
                          isBocesExpanded?.[boces.name] &&
                            <div style={{ width: '100%', padding: 15 }}>
                              {
                                boces.children.map((child: any) => {
                                  return (
                                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%' }} key={child.name}>
                                      <Card style={{ width: '100%', padding: 0, marginBottom: 8, border: '1px solid var(--amplify-colors-neutral-20)' }}>
                                        <Flex
                                          justifyContent='space-between'
                                          alignItems='center'
                                          style={{ fontSize: 14, padding: '12px 15px', cursor: 'pointer', backgroundColor: 'var(--amplify-components-table-row-hover-background-color)' }}
                                          onClick={() => setIsBocesExpanded({ ...isBocesExpanded, [child.id || child.name]: !isBocesExpanded[child.id || child.name] })}
                                        >
                                          <div style={{ fontSize: 16, maxWidth: 460, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{child.name}</div>
                                          <Flex>
                                            <span className='desc-2'>
                                              {child.districts?.length || 0} member district{ child.districts?.length === 1 ? '' : 's' }
                                            </span>
                                            <div>
                                              {
                                                isBocesExpanded?.[child.id || child.name] ?
                                                  <BiChevronUp fontSize={20} /> :
                                                  <BiChevronDown fontSize={20} />
                                              }
                                            </div>
                                          </Flex>
                                        </Flex>
                                          {
                                            isBocesExpanded?.[child.id || child.name] &&
                                              <div style={{ padding: 15 }}>
                                                {
                                                  child?.website && <>
                                                    <div className="desc-7" style={{ marginBottom: 0 }}>Website</div>
                                                    <AmplifyLink target='_blank' href={ child.website }>{ child.website }</AmplifyLink>
                                                  </>
                                                }
                                                {
                                                  child?.address && <>
                                                    <div className="desc-7" style={{ marginTop: 10 }}>Address</div>
                                                    <AmplifyLink target='_blank' href={'https://www.google.com/maps/search/' + child.address}>{ child.address }</AmplifyLink>
                                                  </>
                                                }
                                                {
                                                  child?.contacts && <>
                                                    <div className="desc-7" style={{ marginBottom: 5, marginTop: child?.website || child?.address ? 20 : 0 }}>Contacts</div>
                                                    {
                                                      child.contacts.map((contact: any) => {
                                                        return (
                                                          <div style={{ display: 'flex', alignItems: 'center', width: '100%', marginBottom: 5 }} key={contact.name}>
                                                            <div style={{ flex: 2 }}>{contact.name}<span className="desc-3">, {contact.title}</span></div>
                                                            <div style={{ flex: 1 }}>{contact.email}</div>
                                                          </div>
                                                        )
                                                      })
                                                    }
                                                  </>
                                                }
                                                <div style={{ marginTop: child?.website || child?.contacts ? 20 : 0 }}>
                                                  <div className="desc-7" style={{ marginBottom: 5 }}>Member Districts</div>
                                                  {
                                                    child.districts.map((district: any) => (
                                                      district.id ?
                                                        <div style={{ marginTop: 5 }} key={district.id}>
                                                          <Link to={`/directory/districts/${district.id}`}>{district.name}</Link>
                                                        </div> :
                                                        <div style={{ marginTop: 5 }} key={district.name}>{ district.name }</div>
                                                    ))
                                                  }
                                                </div>
                                              </div>
                                          }
                                      </Card>
                                    </div>
                                  )
                                })
                              }
                            </div>
                        }
                      </div>
                    )
                  })
                }
                {/* <div className="results-row" style={{ alignItems: 'center' }}>
                  <div style={{ width: '400px', display: 'flex', alignItems: 'center' }}>
                    <Link to='/service-agencies/boces' className='desc-6'>OAESD</Link>
                    <div className="tag tag-neutral" style={{ margin: '2px 0 0 10px' }}>Oregon</div>
                  </div>
                  <div>15</div>
                </div> */}
                <div className="results-row" style={{ alignItems: 'center' }}>
                  <div className="desc-3">More service agencies coming soon...</div>
                </div>
              </div>
            </div>
          </div>
        </>
      }
    </GlobalContext.Consumer>
  );
}

export default ServiceAgencies;