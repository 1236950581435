import './TitleCategoryTag.scss';

export default function TitleCategoryTag({ titleCategory = 'unknown' }) {
  const titleCategoryMap: any = {
    'MANAGER': 'Manager',
    'TEACHER': 'Teacher',
    'OTHER': 'Other Staff',
    'UNKNOWN': 'Unknown'
  }
  return <>
    {
      titleCategory && titleCategory !== 'unknown' &&
      <span className={`title-category-tag title-category-tag-${titleCategory.toLowerCase()}`}>
        { titleCategoryMap[titleCategory] }
      </span>
    }
  </>
}
