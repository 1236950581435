import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeCardElement } from '@stripe/stripe-js';
import { toast } from 'react-toastify';
import './StripeCheckoutForm.scss';

import { forwardRef, useImperativeHandle } from 'react';

const StripeCheckoutForm = forwardRef(
  (props: any, ref: React.Ref<any>) => {
    const stripe = useStripe();
    const elements = useElements();

    async function handleSubmit() {
      if (!stripe || !elements) {
        toast.warn('Payment provider is not yet initialized, please try again')
        return;
      }

      const cardElement = elements.getElement(CardElement) as StripeCardElement;
      
      const createTokenRes = await stripe.createToken(cardElement);
      const createPaymentMethodRes = await stripe.createPaymentMethod({ elements })

      if (createTokenRes.error || !createTokenRes.token?.id) {
        throw new Error('Something went wrong adding the credit card, please try again')
      }

      if (createPaymentMethodRes.error || !createPaymentMethodRes.paymentMethod?.id) {
        throw new Error('Something went wrong creating the payment method, please try again')
      }

      return {
        tokenId: createTokenRes.token.id,
        paymentMethodId: createPaymentMethodRes.paymentMethod.id
      }
    };

    useImperativeHandle(ref, () => ({
      handleSubmit,
    }));

    return (
      <div className='stripe-checkout-form-container'>
        <form onSubmit={handleSubmit}>
          <CardElement options={{
            style: {
              base: {
                iconColor: '#5c6670',
                fontFamily: 'Inter, Arial, sans-serif',
                fontSize: '16px',
                fontSmoothing: 'antialiased',
                ':-webkit-autofill': {
                  color: '#5c6670',
                },
                '::placeholder': {
                  color: '#5c6670',
                },
              },
              invalid: {
                iconColor: '#ff0a7a',
                color: '#ff0a7a',
              },
            }
          }}/>
        </form>
      </div>
    );
  }
);

export default StripeCheckoutForm;