import { Link } from 'react-router-dom';
import { useContext } from 'react';
import general from '../../utils/general';
import { GlobalContext } from '../../global-context';

export function TableRowHeaderIDN() {
  return <>
    <div className='results-subheader' style={{ justifyContent: 'flex-end' }}>
      <div style={{ flex: 1, paddingLeft: 40 }}>Name</div>
    </div>
  </>
}

export function TableRowIDN({
  row,
}: {
  row: any
}) {
  const globalContext = useContext(GlobalContext);

  return <>
    <div className='results-row-content'>
      <div className="results-row-main-row">
        <div style={{ flex: 1.8 }}>
          <div>
            <Link to={`/directory/${globalContext.environment.entities.parentInstitution.urlSegment}/${row._id}`} className='main-table-link'>{ general.trim(row._source['name'], 50) }</Link>
          </div>
        </div>
      </div>
    </div>
  </>
}
