import { useContext, useEffect, useState } from 'react';
import { GlobalContext, GlobalContextProps } from '../global-context';
import general from '../utils/general';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { post } from 'aws-amplify/api';
import { Button, Flex, Loader, TextAreaField } from '@aws-amplify/ui-react';
import { TeamDao } from '../dao/team';
import NA from '../custom-components/NA';
import LoadingBars from '../custom-components/LoadingBars';

export function Prospector(props: any) {

  const globalContext = useContext(GlobalContext);
  general.setTitle(document, 'Prospector', globalContext);

  const [isScanning, setIsScanning] = useState(false);
  const [isLoadingUrlScans, setIsLoadingUrlScans] = useState(false);
  const [urls, setUrls] = useState('');
  const [urlScans, setUrlScans] = useState<any>();

  useEffect(() => {
    if (globalContext?.authInfo?.teamId) {
      listUrlScans()
    }
  }, [globalContext.authInfo?.teamId])

  async function listUrlScans() {
    try {
      setIsLoadingUrlScans(true)
      const urlScans = await TeamDao.request({ action: 'LIST_PROSPECTOR_JOBS' }) as any
      general.sortByCreatedAtISO(urlScans?.data?.scans)
      setUrlScans(urlScans)
    } catch {
      toast.warn(`Something went wrong listing URL scans`)
    } finally {
      setIsLoadingUrlScans(false)
    }
  }

  async function onScan() {
    try {
      setIsScanning(true)
      const headers = await general.getAuthorizationHeaders()

      post({
        apiName: 'apiMain',
        path: '/data',
        options: {
          headers,
          body: {
            action: 'CREATE_PROSPECTOR_JOB',
            data: {
              urls
            }
          }
        }
      });
      setTimeout(() => {
        listUrlScans()
      }, 100)
    } catch (err) {
      toast.warn(`Something went wrong scanning URLs`)
    } finally {
      setIsScanning(false)
    }
  }
  
  return <GlobalContext.Consumer>
    {
      (globalContextProps: GlobalContextProps) => <>
        <div className="content content-800">
          <div className='marquee'>Disclaimer: This tool is currently in public beta and might produce incomplete results. Email us to share feedback.</div>
          <Flex justifyContent='space-between' alignItems='center' style={{ marginTop: 30, marginBottom: 5 }}>
            <h1 style={{ margin: 0 }}>Prospector</h1>
          </Flex>
          <div className='desc-4' style={{ marginBottom: 10 }}>Scan URLs and retrieve the contacts listed on them in a structured format. Enter one URL per line, with a maximum of 10 URLs.</div>
          <TextAreaField
            label=""
            value={urls}
            onChange={(e) => setUrls(e.target.value)}
            disabled={isScanning}
            rows={12}
            placeholder='https://www.someschool.com/directory/staff...'
          />
          <Flex justifyContent='end' style={{ marginBottom: 40 }}>
            <Button
              variation='primary'
              style={{ marginTop: '1rem' }}
              disabled={isScanning || !urls.length || urls.split('\n').length > 10}
              onClick={onScan}
            >
              { isScanning && <Loader style={{ marginRight: 10 }} /> }{ isScanning ? 'Scanning...' : `Scan` }
            </Button>
          </Flex>
          {
            isLoadingUrlScans
              ? <LoadingBars style={{ marginTop: 70 }} numberOfRows={5} />
              : urlScans?.data?.scans?.length > 0 &&
                <div style={{ marginBottom: 100 }}>
                  <Flex justifyContent='space-between' alignItems='baseline'>
                    <h4 style={{ marginBottom: 12 }}>Your Scans</h4>
                    <div>
                      <Button onClick={() => listUrlScans()} className='tiny'>
                        Refresh
                      </Button>
                    </div>
                  </Flex>
      
                  <div className="main-card" style={{ marginTop: 10 }}>
                    <div className='results-subheader' style={{ justifyContent: 'flex-start' }}>
                      <div style={{ width: '130px' }}>ID</div>
                      <div style={{ width: '370px' }}>Started by</div>
                      <div style={{ width: '100px' }}># of URLs</div>
                      {/* <div style={{ width: '100px' }}>Status</div> */}
                    </div>
                    <div>
                      {
                        urlScans?.data?.scans?.map((scan: any) => {
                          return <div key={scan.createdAt} className='results-row'>
                            <div style={{ width: '130px' }}>
                              <Link to={`/prospector/${scan.id}`}>
                                { scan?.id?.substring(0, 8) }
                              </Link>
                              {/* {
                                scan?.status === 'DONE'
                                  ? <Link to={`/prospector/${scan.id}`}>
                                      { scan?.id?.substring(0, 8) }
                                    </Link>
                                  : <span
                                      style={{ color: 'var(--amplify-colors-neutral-80)' }}
                                      data-tooltip-id="main-tooltip"
                                      data-tooltip-content="Scan in progress, check back soon. Typically completes within a few minutes, but can take up to 15 minutes. Refresh for updates."
                                    >
                                      { scan?.id?.substring(0, 8) }
                                    </span>
                              } */}
                            </div>
                            <div style={{ width: '370px' }}>
                              { scan?.createdByUserName }, { general.daysAgo(scan.createdAt) }
                            </div>
                            <div style={{ width: '100px' }}>{ scan?.numberOfUrls || <NA /> }</div>
                            {/* <div style={{ width: '100px' }}>
                              {
                                scan?.status
                                  ? <div
                                      style={{ display: 'inline-block' }}
                                      className={`tag ${ scan?.status === 'DONE' ? 'tag-green' : scan?.status === 'RUNNING' ? 'tag-yellow' : 'tag-gray' }`}
                                    >
                                      { scan?.status }
                                    </div>
                                  : <NA />
                              }
                            </div> */}
                          </div>
                        })
                      }
                    </div>
                  </div>
                </div>
          }
        </div>
      </>
    }
  </GlobalContext.Consumer>
}
