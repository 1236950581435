import './InstitutionTypeTag.scss';

export default function InstitutionTypeTag({ category = 'unknown' }) {
  const categoryMap: any = {
    'SCHOOL': 'School',
    'DISTRICT': 'District',
    'IDN': 'IDN',
    'HOSPITAL': 'Hospital',
    'ACUTE_CARE_HOSPITAL': 'Acute Care Hospitals',
    'ACUTE_CARE_VETERANS': 'Acute Care - Veterans Administration',
    'ACUTE_CARE_DOD': 'Acute Care - Department of Defense',
    'CRITICAL_ACCESS': 'Critical Access Hospitals',
    'CHILDRENS': 'Childrens',
    'PSYCHIATRIC': 'Psychiatric'
  }
  return <>
    {
      category !== 'unknown' &&
      <span className={`institution-type-tag institution-type-tag-${category.toLowerCase()}`}>
        { categoryMap[category] }
      </span>
    }
  </>
}
